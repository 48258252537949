/**
* Tailwind build which works with Taro
*/

.blue {
    color: #5794f8
}

.red {
    color: #fa5151
}

.green {
    color: #2fd43a
}

.orange {
    color: #ff9900
}

.gray-333 {
    color: #333333;
}

.gray-666 {
    color: #666;
}

.gray-999 {
    color: #999;
}

.gray-ccc {
    color: #ccc;
}

.bg-blue {
    background-color: #5794f8
}

.bg-red {
    background-color: #fa5151
}

.bg-green {
    background-color: #2fd43a
}

.bg-orange {
    background-color: #ff9900
}

.bg-gray-333 {
    background-color: #333333;
}

.bg-gray-666 {
    background-color: #666;
}

.bg-gray-999 {
    background-color: #999;
}

.bg-gray-ccc {
    background-color: #ccc;
}

.container {
    width: 100%
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.appearance-none {
    appearance: none
}

.bg-fixed {
    background-attachment: fixed
}

.bg-local {
    background-attachment: local
}

.bg-scroll {
    background-attachment: scroll
}

.bg-clip-border {
    background-clip: border-box
}

.bg-clip-padding {
    background-clip: padding-box
}

.bg-clip-content {
    background-clip: content-box
}

.bg-clip-text {
    background-clip: text
}

.bg-transparent {
    background-color: transparent
}

.bg-current {
    background-color: currentColor
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

.bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

.bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

.bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(255,236,235, var(--tw-bg-opacity))
}

.bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

.bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

.bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

.bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

.bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

.bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

.bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

.bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

.bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

.bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

.bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

.bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

.bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

.bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

.bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

.bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

.bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

.bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

.bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

.bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

.bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

.bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

.bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

.bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

.bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(235,245,255, var(--tw-bg-opacity))
}

.bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

.bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

.bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 71, 102, var(--tw-bg-opacity))
}

.bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

.bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

.bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

.bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

.bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

.bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

.bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

.bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

.bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

.bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

.bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

.bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

.bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

.bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

.bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

.bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

.bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

.bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

.bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

.bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

.bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

.bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

.bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

.bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

.bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

.bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

.bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

.bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

.bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

.bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

.bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

.bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

.bg-none {
    background-image: none
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
}

.bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
}

.bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
}

.from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

.from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

.from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

.from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

.from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

.from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

.from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

.from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

.from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

.from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

.from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

.from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

.from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

.from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

.from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

.from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

.from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

.from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

.from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

.from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

.from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

.from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

.from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

.from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

.from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

.from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

.from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

.from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

.from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

.from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

.from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

.from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

.from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

.from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

.from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

.from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

.from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

.from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

.from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

.from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

.from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

.from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

.from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

.from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

.from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

.from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

.from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

.from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

.from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

.from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

.from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

.from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

.from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

.from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

.from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

.from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

.from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

.from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

.from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

.from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

.from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

.from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

.from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

.from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

.from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

.from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

.from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

.from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

.from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

.from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

.from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

.from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

.from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

.from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

.from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

.from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

.from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

.from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

.from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

.from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

.via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

.via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

.via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

.via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

.via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

.via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

.via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

.via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

.via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

.via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

.via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

.via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

.via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

.via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

.via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

.via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

.via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

.via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

.via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

.via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

.via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

.via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

.via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

.via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

.via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

.via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

.via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

.via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

.via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

.via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

.via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

.via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

.via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

.via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

.via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

.via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

.via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

.via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

.via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

.via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

.via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

.via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

.via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

.via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

.via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

.via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

.via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

.via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

.via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

.via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

.via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

.via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

.via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

.via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

.via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

.via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

.via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

.via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

.via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

.via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

.via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

.via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

.via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

.via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

.via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

.via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

.via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

.via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

.via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

.via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

.via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

.via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

.via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

.via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

.via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

.via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

.via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

.via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

.via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

.via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

.to-transparent {
    --tw-gradient-to: transparent
}

.to-current {
    --tw-gradient-to: currentColor
}

.to-black {
    --tw-gradient-to: #000
}

.to-white {
    --tw-gradient-to: #fff
}

.to-gray-50 {
    --tw-gradient-to: #f9fafb
}

.to-gray-100 {
    --tw-gradient-to: #f3f4f6
}

.to-gray-200 {
    --tw-gradient-to: #e5e7eb
}

.to-gray-300 {
    --tw-gradient-to: #d1d5db
}

.to-gray-400 {
    --tw-gradient-to: #9ca3af
}

.to-gray-500 {
    --tw-gradient-to: #6b7280
}

.to-gray-600 {
    --tw-gradient-to: #4b5563
}

.to-gray-700 {
    --tw-gradient-to: #374151
}

.to-gray-800 {
    --tw-gradient-to: #1f2937
}

.to-gray-900 {
    --tw-gradient-to: #111827
}

.to-red-50 {
    --tw-gradient-to: #fef2f2
}

.to-red-100 {
    --tw-gradient-to: #fee2e2
}

.to-red-200 {
    --tw-gradient-to: #fecaca
}

.to-red-300 {
    --tw-gradient-to: #fca5a5
}

.to-red-400 {
    --tw-gradient-to: #f87171
}

.to-red-500 {
    --tw-gradient-to: #ef4444
}

.to-red-600 {
    --tw-gradient-to: #dc2626
}

.to-red-700 {
    --tw-gradient-to: #b91c1c
}

.to-red-800 {
    --tw-gradient-to: #991b1b
}

.to-red-900 {
    --tw-gradient-to: #7f1d1d
}

.to-yellow-50 {
    --tw-gradient-to: #fffbeb
}

.to-yellow-100 {
    --tw-gradient-to: #fef3c7
}

.to-yellow-200 {
    --tw-gradient-to: #fde68a
}

.to-yellow-300 {
    --tw-gradient-to: #fcd34d
}

.to-yellow-400 {
    --tw-gradient-to: #fbbf24
}

.to-yellow-500 {
    --tw-gradient-to: #f59e0b
}

.to-yellow-600 {
    --tw-gradient-to: #d97706
}

.to-yellow-700 {
    --tw-gradient-to: #b45309
}

.to-yellow-800 {
    --tw-gradient-to: #92400e
}

.to-yellow-900 {
    --tw-gradient-to: #78350f
}

.to-green-50 {
    --tw-gradient-to: #ecfdf5
}

.to-green-100 {
    --tw-gradient-to: #d1fae5
}

.to-green-200 {
    --tw-gradient-to: #a7f3d0
}

.to-green-300 {
    --tw-gradient-to: #6ee7b7
}

.to-green-400 {
    --tw-gradient-to: #34d399
}

.to-green-500 {
    --tw-gradient-to: #10b981
}

.to-green-600 {
    --tw-gradient-to: #059669
}

.to-green-700 {
    --tw-gradient-to: #047857
}

.to-green-800 {
    --tw-gradient-to: #065f46
}

.to-green-900 {
    --tw-gradient-to: #064e3b
}

.to-blue-50 {
    --tw-gradient-to: #eff6ff
}

.to-blue-100 {
    --tw-gradient-to: #dbeafe
}

.to-blue-200 {
    --tw-gradient-to: #bfdbfe
}

.to-blue-300 {
    --tw-gradient-to: #93c5fd
}

.to-blue-400 {
    --tw-gradient-to: #60a5fa
}

.to-blue-500 {
    --tw-gradient-to: #3b82f6
}

.to-blue-600 {
    --tw-gradient-to: #2563eb
}

.to-blue-700 {
    --tw-gradient-to: #1d4ed8
}

.to-blue-800 {
    --tw-gradient-to: #1e40af
}

.to-blue-900 {
    --tw-gradient-to: #1e3a8a
}

.to-indigo-50 {
    --tw-gradient-to: #eef2ff
}

.to-indigo-100 {
    --tw-gradient-to: #e0e7ff
}

.to-indigo-200 {
    --tw-gradient-to: #c7d2fe
}

.to-indigo-300 {
    --tw-gradient-to: #a5b4fc
}

.to-indigo-400 {
    --tw-gradient-to: #818cf8
}

.to-indigo-500 {
    --tw-gradient-to: #6366f1
}

.to-indigo-600 {
    --tw-gradient-to: #4f46e5
}

.to-indigo-700 {
    --tw-gradient-to: #4338ca
}

.to-indigo-800 {
    --tw-gradient-to: #3730a3
}

.to-indigo-900 {
    --tw-gradient-to: #312e81
}

.to-purple-50 {
    --tw-gradient-to: #f5f3ff
}

.to-purple-100 {
    --tw-gradient-to: #ede9fe
}

.to-purple-200 {
    --tw-gradient-to: #ddd6fe
}

.to-purple-300 {
    --tw-gradient-to: #c4b5fd
}

.to-purple-400 {
    --tw-gradient-to: #a78bfa
}

.to-purple-500 {
    --tw-gradient-to: #8b5cf6
}

.to-purple-600 {
    --tw-gradient-to: #7c3aed
}

.to-purple-700 {
    --tw-gradient-to: #6d28d9
}

.to-purple-800 {
    --tw-gradient-to: #5b21b6
}

.to-purple-900 {
    --tw-gradient-to: #4c1d95
}

.to-pink-50 {
    --tw-gradient-to: #fdf2f8
}

.to-pink-100 {
    --tw-gradient-to: #fce7f3
}

.to-pink-200 {
    --tw-gradient-to: #fbcfe8
}

.to-pink-300 {
    --tw-gradient-to: #f9a8d4
}

.to-pink-400 {
    --tw-gradient-to: #f472b6
}

.to-pink-500 {
    --tw-gradient-to: #ec4899
}

.to-pink-600 {
    --tw-gradient-to: #db2777
}

.to-pink-700 {
    --tw-gradient-to: #be185d
}

.to-pink-800 {
    --tw-gradient-to: #9d174d
}

.to-pink-900 {
    --tw-gradient-to: #831843
}

.bg-opacity-0 {
    --tw-bg-opacity: 0
}

.bg-opacity-5 {
    --tw-bg-opacity: 0.05
}

.bg-opacity-10 {
    --tw-bg-opacity: 0.1
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2
}

.bg-opacity-25 {
    --tw-bg-opacity: 0.25
}

.bg-opacity-30 {
    --tw-bg-opacity: 0.3
}

.bg-opacity-40 {
    --tw-bg-opacity: 0.4
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5
}

.bg-opacity-60 {
    --tw-bg-opacity: 0.6
}

.bg-opacity-70 {
    --tw-bg-opacity: 0.7
}

.bg-opacity-75 {
    --tw-bg-opacity: 0.75
}

.bg-opacity-80 {
    --tw-bg-opacity: 0.8
}

.bg-opacity-90 {
    --tw-bg-opacity: 0.9
}

.bg-opacity-95 {
    --tw-bg-opacity: 0.95
}

.bg-opacity-100 {
    --tw-bg-opacity: 1
}

.bg-bottom {
    background-position: bottom
}

.bg-center {
    background-position: center
}

.bg-left {
    background-position: left
}

.bg-left-bottom {
    background-position: left bottom
}

.bg-left-top {
    background-position: left top
}

.bg-right {
    background-position: right
}

.bg-right-bottom {
    background-position: right bottom
}

.bg-right-top {
    background-position: right top
}

.bg-top {
    background-position: top
}

.bg-repeat {
    background-repeat: repeat
}

.bg-no-repeat {
    background-repeat: no-repeat
}

.bg-repeat-x {
    background-repeat: repeat-x
}

.bg-repeat-y {
    background-repeat: repeat-y
}

.bg-repeat-round {
    background-repeat: round
}

.bg-repeat-space {
    background-repeat: space
}

.bg-auto {
    background-size: auto
}

.bg-cover {
    background-size: cover
}

.bg-contain {
    background-size: contain
}

.border-collapse {
    border-collapse: collapse
}

.border-separate {
    border-collapse: separate
}

.border-transparent {
    border-color: transparent
}

.border-current {
    border-color: currentColor
}

.border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

.border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

.border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

.border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

.border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

.border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

.border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

.border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

.border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

.border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

.border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

.border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

.border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

.border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

.border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

.border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

.border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

.border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

.border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

.border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

.border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

.border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

.border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

.border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

.border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

.border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

.border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

.border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

.border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

.border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

.border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

.border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

.border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

.border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

.border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

.border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

.border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

.border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

.border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

.border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

.border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

.border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

.border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

.border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

.border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

.border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

.border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

.border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

.border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

.border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

.border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

.border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

.border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

.border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

.border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

.border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

.border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

.border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

.border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

.border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

.border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

.border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

.border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

.border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

.border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

.border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

.border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

.border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

.border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

.border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

.border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

.border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

.border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

.border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

.border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

.border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

.border-opacity-0 {
    --tw-border-opacity: 0
}

.border-opacity-5 {
    --tw-border-opacity: 0.05
}

.border-opacity-10 {
    --tw-border-opacity: 0.1
}

.border-opacity-20 {
    --tw-border-opacity: 0.2
}

.border-opacity-25 {
    --tw-border-opacity: 0.25
}

.border-opacity-30 {
    --tw-border-opacity: 0.3
}

.border-opacity-40 {
    --tw-border-opacity: 0.4
}

.border-opacity-50 {
    --tw-border-opacity: 0.5
}

.border-opacity-60 {
    --tw-border-opacity: 0.6
}

.border-opacity-70 {
    --tw-border-opacity: 0.7
}

.border-opacity-75 {
    --tw-border-opacity: 0.75
}

.border-opacity-80 {
    --tw-border-opacity: 0.8
}

.border-opacity-90 {
    --tw-border-opacity: 0.9
}

.border-opacity-95 {
    --tw-border-opacity: 0.95
}

.border-opacity-100 {
    --tw-border-opacity: 1
}

.rounded-none {
    border-radius: 0px
}

.rounded-sm {
    border-radius: 2px
}

.rounded {
    border-radius: 4px
}

.rounded-md {
    border-radius: 6px
}

.rounded-lg {
    border-radius: 8px
}

.rounded-xl {
    border-radius: 12px
}

.rounded-2xl {
    border-radius: 16px
}

.rounded-3xl {
    border-radius: 24px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
}

.rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
}

.rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
}

.rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
}

.rounded-t-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}

.rounded-r-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.rounded-b-sm {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.rounded-l-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px
}

.rounded-t {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.rounded-r {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.rounded-b {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.rounded-l {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.rounded-t-md {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.rounded-r-md {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.rounded-b-md {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.rounded-l-md {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.rounded-t-lg {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.rounded-r-lg {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
}

.rounded-b-lg {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px
}

.rounded-l-lg {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.rounded-t-xl {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px
}

.rounded-r-xl {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px
}

.rounded-b-xl {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px
}

.rounded-l-xl {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px
}

.rounded-t-2xl {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px
}

.rounded-r-2xl {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px
}

.rounded-b-2xl {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px
}

.rounded-l-2xl {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px
}

.rounded-t-3xl {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px
}

.rounded-r-3xl {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px
}

.rounded-b-3xl {
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px
}

.rounded-l-3xl {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px
}

.rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
}

.rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
}

.rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
}

.rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
}

.rounded-tl-none {
    border-top-left-radius: 0px
}

.rounded-tr-none {
    border-top-right-radius: 0px
}

.rounded-br-none {
    border-bottom-right-radius: 0px
}

.rounded-bl-none {
    border-bottom-left-radius: 0px
}

.rounded-tl-sm {
    border-top-left-radius: 2px
}

.rounded-tr-sm {
    border-top-right-radius: 2px
}

.rounded-br-sm {
    border-bottom-right-radius: 2px
}

.rounded-bl-sm {
    border-bottom-left-radius: 2px
}

.rounded-tl {
    border-top-left-radius: 4px
}

.rounded-tr {
    border-top-right-radius: 4px
}

.rounded-br {
    border-bottom-right-radius: 4px
}

.rounded-bl {
    border-bottom-left-radius: 4px
}

.rounded-tl-md {
    border-top-left-radius: 6px
}

.rounded-tr-md {
    border-top-right-radius: 6px
}

.rounded-br-md {
    border-bottom-right-radius: 6px
}

.rounded-bl-md {
    border-bottom-left-radius: 6px
}

.rounded-tl-lg {
    border-top-left-radius: 8px
}

.rounded-tr-lg {
    border-top-right-radius: 8px
}

.rounded-br-lg {
    border-bottom-right-radius: 8px
}

.rounded-bl-lg {
    border-bottom-left-radius: 8px
}

.rounded-tl-xl {
    border-top-left-radius: 12px
}

.rounded-tr-xl {
    border-top-right-radius: 12px
}

.rounded-br-xl {
    border-bottom-right-radius: 12px
}

.rounded-bl-xl {
    border-bottom-left-radius: 12px
}

.rounded-tl-2xl {
    border-top-left-radius: 16px
}

.rounded-tr-2xl {
    border-top-right-radius: 16px
}

.rounded-br-2xl {
    border-bottom-right-radius: 16px
}

.rounded-bl-2xl {
    border-bottom-left-radius: 16px
}

.rounded-tl-3xl {
    border-top-left-radius: 24px
}

.rounded-tr-3xl {
    border-top-right-radius: 24px
}

.rounded-br-3xl {
    border-bottom-right-radius: 24px
}

.rounded-bl-3xl {
    border-bottom-left-radius: 24px
}

.rounded-tl-full {
    border-top-left-radius: 9999px
}

.rounded-tr-full {
    border-top-right-radius: 9999px
}

.rounded-br-full {
    border-bottom-right-radius: 9999px
}

.rounded-bl-full {
    border-bottom-left-radius: 9999px
}

.border-solid {
    border-style: solid
}

.border-dashed {
    border-style: dashed
}

.border-dotted {
    border-style: dotted
}

.border-double {
    border-style: double
}

.border-none {
    border-style: none
}

.border-0 {
    border-width: 0px
}

.border-2 {
    border-width: 2px
}

.border-4 {
    border-width: 4px
}

.border-8 {
    border-width: 8px
}

.border {
    border-width: 1px
}

.border-t-0 {
    border-top-width: 0px
}

.border-r-0 {
    border-right-width: 0px
}

.border-b-0 {
    border-bottom-width: 0px
}

.border-l-0 {
    border-left-width: 0px
}

.border-t-2 {
    border-top-width: 2px
}

.border-r-2 {
    border-right-width: 2px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-l-2 {
    border-left-width: 2px
}

.border-t-4 {
    border-top-width: 4px
}

.border-r-4 {
    border-right-width: 4px
}

.border-b-4 {
    border-bottom-width: 4px
}

.border-l-4 {
    border-left-width: 4px
}

.border-t-8 {
    border-top-width: 8px
}

.border-r-8 {
    border-right-width: 8px
}

.border-b-8 {
    border-bottom-width: 8px
}

.border-l-8 {
    border-left-width: 8px
}

.border-t {
    border-top-width: 1px
}

.border-r {
    border-right-width: 1px
}

.border-b {
    border-bottom-width: 1px
}

.border-l {
    border-left-width: 1px
}

.box-border {
    box-sizing: border-box
}

.box-content {
    box-sizing: content-box
}

.cursor-auto {
    cursor: auto
}

.cursor-default {
    cursor: default
}

.cursor-pointer {
    cursor: pointer
}

.cursor-wait {
    cursor: wait
}

.cursor-text {
    cursor: text
}

.cursor-move {
    cursor: move
}

.cursor-help {
    cursor: help
}

.cursor-not-allowed {
    cursor: not-allowed
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.table-caption {
    display: table-caption
}

.table-cell {
    display: table-cell
}

.table-column {
    display: table-column
}

.table-column-group {
    display: table-column-group
}

.table-footer-group {
    display: table-footer-group
}

.table-header-group {
    display: table-header-group
}

.table-row-group {
    display: table-row-group
}

.table-row {
    display: table-row
}

.flow-root {
    display: flow-root
}

.grid {
    display: grid
}

.inline-grid {
    display: inline-grid
}

.contents {
    display: contents
}

.hidden {
    display: none
}

.flex-row {
    flex-direction: row
}

.flex-row-reverse {
    flex-direction: row-reverse
}

.flex-col {
    flex-direction: column
}

.flex-col-reverse {
    flex-direction: column-reverse
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse
}

.flex-nowrap {
    flex-wrap: nowrap
}

.place-items-auto {
    place-items: auto
}

.place-items-start {
    place-items: start
}

.place-items-end {
    place-items: end
}

.place-items-center {
    place-items: center
}

.place-items-stretch {
    place-items: stretch
}

.place-content-center {
    place-content: center
}

.place-content-start {
    place-content: start
}

.place-content-end {
    place-content: end
}

.place-content-between {
    place-content: space-between
}

.place-content-around {
    place-content: space-around
}

.place-content-evenly {
    place-content: space-evenly
}

.place-content-stretch {
    place-content: stretch
}

.place-self-auto {
    place-self: auto
}

.place-self-start {
    place-self: start
}

.place-self-end {
    place-self: end
}

.place-self-center {
    place-self: center
}

.place-self-stretch {
    place-self: stretch
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.items-baseline {
    align-items: baseline
}

.items-stretch {
    align-items: stretch
}

.content-center {
    align-content: center
}

.content-start {
    align-content: flex-start
}

.content-end {
    align-content: flex-end
}

.content-between {
    align-content: space-between
}

.content-around {
    align-content: space-around
}

.content-evenly {
    align-content: space-evenly
}

.self-auto {
    align-self: auto
}

.self-start {
    align-self: flex-start
}

.self-end {
    align-self: flex-end
}

.self-center {
    align-self: center
}

.self-stretch {
    align-self: stretch
}

.justify-items-auto {
    justify-items: auto
}

.justify-items-start {
    justify-items: start
}

.justify-items-end {
    justify-items: end
}

.justify-items-center {
    justify-items: center
}

.justify-items-stretch {
    justify-items: stretch
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-around {
    justify-content: space-around
}

.justify-evenly {
    justify-content: space-evenly
}

.justify-self-auto {
    justify-self: auto
}

.justify-self-start {
    justify-self: start
}

.justify-self-end {
    justify-self: end
}

.justify-self-center {
    justify-self: center
}

.justify-self-stretch {
    justify-self: stretch
}

.flex-1 {
    flex: 1 1 0%
}

.flex-auto {
    flex: 1 1 auto
}

.flex-initial {
    flex: 0 1 auto
}

.flex-none {
    flex: none
}

.flex-grow-0 {
    flex-grow: 0
}

.flex-grow {
    flex-grow: 1
}

.flex-shrink-0 {
    flex-shrink: 0
}

.flex-shrink {
    flex-shrink: 1
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.order-first {
    order: -9999
}

.order-last {
    order: 9999
}

.order-none {
    order: 0
}

.float-right {
    float: right
}

.float-left {
    float: left
}

.float-none {
    float: none
}

.clear-left {
    clear: left
}

.clear-right {
    clear: right
}

.clear-both {
    clear: both
}

.clear-none {
    clear: none
}

.font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
}

.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.font-thin {
    font-weight: 100
}

.font-extralight {
    font-weight: 200
}

.font-light {
    font-weight: 300
}

.font-normal {
    font-weight: 400
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.font-bold {
    font-weight: 700
}

.font-extrabold {
    font-weight: 800
}

.font-black {
    font-weight: 900
}

.h-0 {
    height: 0px
}

.h-1 {
    height: 4px
}

.h-2 {
    height: 8px
}

.h-3 {
    height: 12px
}

.h-4 {
    height: 16px
}

.h-5 {
    height: 20px
}

.h-6 {
    height: 24px
}

.h-7 {
    height: 28px
}

.h-8 {
    height: 32px
}

.h-9 {
    height: 36px
}

.h-10 {
    height: 40px
}

.h-11 {
    height: 44px
}

.h-12 {
    height: 48px
}

.h-14 {
    height: 56px
}

.h-16 {
    height: 64px
}

.h-20 {
    height: 80px
}

.h-24 {
    height: 96px
}

.h-28 {
    height: 112px
}

.h-32 {
    height: 128px
}

.h-36 {
    height: 144px
}

.h-40 {
    height: 160px
}

.h-44 {
    height: 176px
}

.h-48 {
    height: 192px
}

.h-52 {
    height: 208px
}

.h-56 {
    height: 224px
}

.h-60 {
    height: 240px
}

.h-64 {
    height: 256px
}

.h-72 {
    height: 288px
}

.h-80 {
    height: 320px
}

.h-96 {
    height: 384px
}

.h-auto {
    height: auto
}

.h-px {
    height: 1px
}

.h-0d5 {
    height: 2px
}

.h-1d5 {
    height: 6px
}

.h-2d5 {
    height: 10px
}

.h-3d5 {
    height: 14px
}

.h-1_2 {
    height: 50%
}

.h-1_3 {
    height: 33.333333%
}

.h-2_3 {
    height: 66.666667%
}

.h-1_4 {
    height: 25%
}

.h-2_4 {
    height: 50%
}

.h-3_4 {
    height: 75%
}

.h-1_5 {
    height: 20%
}

.h-2_5 {
    height: 40%
}

.h-3_5 {
    height: 60%
}

.h-4_5 {
    height: 80%
}

.h-1_6 {
    height: 16.666667%
}

.h-2_6 {
    height: 33.333333%
}

.h-3_6 {
    height: 50%
}

.h-4_6 {
    height: 66.666667%
}

.h-5_6 {
    height: 83.333333%
}

.h-full {
    height: 100%
}

.h-screen {
    height: 100vh
}

.text-xs {
    font-size: 12px;
    line-height: 16px
}

.text-sm {
    font-size: 14px;
    line-height: 20px
}

.text-base {
    font-size: 16px;
    line-height: 24px
}

.text-lg {
    font-size: 18px;
    line-height: 28px
}

.text-xl {
    font-size: 20px;
    line-height: 28px
}

.text-2xl {
    font-size: 24px;
    line-height: 32px
}

.text-3xl {
    font-size: 28px;
    line-height: 36px
}

.text-4xl {
    font-size: 32px;
    line-height: 40px
}

.text-5xl {
    font-size: 36px;
    line-height: 1
}

.text-6xl {
    font-size: 40px;
    line-height: 1
}

.text-7xl {
    font-size: 44px;
    line-height: 1
}

.text-8xl {
    font-size: 48px;
    line-height: 1
}

.text-9xl {
    font-size: 56px;
    line-height: 1
}

.leading-3 {
    line-height: 12px
}

.leading-4 {
    line-height: 16px
}

.leading-5 {
    line-height: 20px
}

.leading-6 {
    line-height: 24px
}

.leading-7 {
    line-height: 28px
}

.leading-8 {
    line-height: 32px
}

.leading-9 {
    line-height: 36px
}

.leading-10 {
    line-height: 40px
}

.leading-none {
    line-height: 1
}

.leading-tight {
    line-height: 1.25
}

.leading-snug {
    line-height: 1.375
}

.leading-normal {
    line-height: 1.5
}

.leading-relaxed {
    line-height: 1.625
}

.leading-loose {
    line-height: 2
}

.list-inside {
    list-style-position: inside
}

.list-outside {
    list-style-position: outside
}

.list-none {
    list-style-type: none
}

.list-disc {
    list-style-type: disc
}

.list-decimal {
    list-style-type: decimal
}

.m-0 {
    margin: 0px
}

.m-1 {
    margin: 4px
}

.m-2 {
    margin: 8px
}

.m-3 {
    margin: 12px
}

.m-4 {
    margin: 16px
}

.m-5 {
    margin: 20px
}

.m-6 {
    margin: 24px
}

.m-7 {
    margin: 28px
}

.m-8 {
    margin: 32px
}

.m-9 {
    margin: 36px
}

.m-10 {
    margin: 40px
}

.m-11 {
    margin: 44px
}

.m-12 {
    margin: 48px
}

.m-14 {
    margin: 60px
}

.m-16 {
    margin: 64px
}

.m-20 {
    margin: 80px
}

.m-24 {
    margin: 96px
}

.m-28 {
    margin: 112px
}

.m-32 {
    margin: 128px
}

.m-36 {
    margin: 144px
}

.m-40 {
    margin: 160px
}

.m-44 {
    margin: 176px
}

.m-48 {
    margin: 192px
}

.m-52 {
    margin: 208px
}

.m-56 {
    margin: 224px
}

.m-60 {
    margin: 240px
}

.m-64 {
    margin: 256px
}

.m-72 {
    margin: 288px
}

.m-80 {
    margin: 320px
}

.m-96 {
    margin: 384px
}

.m-auto {
    margin: auto
}

.m-px {
    margin: 1px
}

.m-0d5 {
    margin: 2px
}

.m-1d5 {
    margin: 6px
}

.m-2d5 {
    margin: 10px
}

.m-3d5 {
    margin: 14px
}

.-m-0 {
    margin: 0px
}

.-m-1 {
    margin: -4px
}

.-m-2 {
    margin: -8px
}

.-m-3 {
    margin: -12px
}

.-m-4 {
    margin: -16px
}

.-m-5 {
    margin: -20px
}

.-m-6 {
    margin: -24px
}

.-m-7 {
    margin: -28px
}

.-m-8 {
    margin: -32px
}

.-m-9 {
    margin: -36px
}

.-m-10 {
    margin: -40px
}

.-m-11 {
    margin: -44px
}

.-m-12 {
    margin: -48px
}

.-m-14 {
    margin: -56px
}

.-m-16 {
    margin: -64px
}

.-m-20 {
    margin: -80px
}

.-m-24 {
    margin: -96px
}

.-m-28 {
    margin: -112px
}

.-m-32 {
    margin: -128px
}

.-m-36 {
    margin: -144px
}

.-m-40 {
    margin: -160px
}

.-m-44 {
    margin: -176px
}

.-m-48 {
    margin: -192px
}

.-m-52 {
    margin: -208px
}

.-m-56 {
    margin: -224px
}

.-m-60 {
    margin: -240px
}

.-m-64 {
    margin: -256px
}

.-m-72 {
    margin: -288px
}

.-m-80 {
    margin: -320px
}

.-m-96 {
    margin: -384px
}

.-m-px {
    margin: -1px
}

.-m-0d5 {
    margin: -2px
}

.-m-1d5 {
    margin: -6px
}

.-m-2d5 {
    margin: -10px
}

.-m-3d5 {
    margin: -14px
}

.my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}

.mx-0 {
    margin-left: 0px;
    margin-right: 0px
}

.my-1 {
    margin-top: 4px;
    margin-bottom: 4px
}

.mx-1 {
    margin-left: 4px;
    margin-right: 4px
}

.my-2 {
    margin-top: 8px;
    margin-bottom: 8px
}

.mx-2 {
    margin-left: 8px;
    margin-right: 8px
}

.my-3 {
    margin-top: 12px;
    margin-bottom: 12px
}

.mx-3 {
    margin-left: 12px;
    margin-right: 12px
}

.my-4 {
    margin-top: 16px;
    margin-bottom: 16px
}

.mx-4 {
    margin-left: 16px;
    margin-right: 16px
}

.my-5 {
    margin-top: 20px;
    margin-bottom: 20px
}

.mx-5 {
    margin-left: 20px;
    margin-right: 20px
}

.my-6 {
    margin-top: 24px;
    margin-bottom: 24px
}

.mx-6 {
    margin-left: 24px;
    margin-right: 24px
}

.my-7 {
    margin-top: 28px;
    margin-bottom: 28px
}

.mx-7 {
    margin-left: 28px;
    margin-right: 28px
}

.my-8 {
    margin-top: 32px;
    margin-bottom: 32px
}

.mx-8 {
    margin-left: 32px;
    margin-right: 32px
}

.my-9 {
    margin-top: 36px;
    margin-bottom: 36px
}

.mx-9 {
    margin-left: 36px;
    margin-right: 36px
}

.my-10 {
    margin-top: 40px;
    margin-bottom: 40px
}

.mx-10 {
    margin-left: 40px;
    margin-right: 40px
}

.my-11 {
    margin-top: 44px;
    margin-bottom: 44px
}

.mx-11 {
    margin-left: 44px;
    margin-right: 44px
}

.my-12 {
    margin-top: 48px;
    margin-bottom: 48px
}

.mx-12 {
    margin-left: 48px;
    margin-right: 48px
}

.my-14 {
    margin-top: 56px;
    margin-bottom: 56px
}

.mx-14 {
    margin-left: 56px;
    margin-right: 56px
}

.my-16 {
    margin-top: 64px;
    margin-bottom: 64px
}

.mx-16 {
    margin-left: 64px;
    margin-right: 64px
}

.my-20 {
    margin-top: 80px;
    margin-bottom: 80px
}

.mx-20 {
    margin-left: 80px;
    margin-right: 80px
}

.my-24 {
    margin-top: 96px;
    margin-bottom: 96px
}

.mx-24 {
    margin-left: 96px;
    margin-right: 96px
}

.my-28 {
    margin-top: 112px;
    margin-bottom: 112px
}

.mx-28 {
    margin-left: 112px;
    margin-right: 112px
}

.my-32 {
    margin-top: 128px;
    margin-bottom: 128px
}

.mx-32 {
    margin-left: 128px;
    margin-right: 128px
}

.my-36 {
    margin-top: 144px;
    margin-bottom: 144px
}

.mx-36 {
    margin-left: 144px;
    margin-right: 144px
}

.my-40 {
    margin-top: 160px;
    margin-bottom: 160px
}

.mx-40 {
    margin-left: 160px;
    margin-right: 160px
}

.my-44 {
    margin-top: 176px;
    margin-bottom: 176px
}

.mx-44 {
    margin-left: 176px;
    margin-right: 176px
}

.my-48 {
    margin-top: 192px;
    margin-bottom: 192px
}

.mx-48 {
    margin-left: 192px;
    margin-right: 192px
}

.my-52 {
    margin-top: 208px;
    margin-bottom: 208px
}

.mx-52 {
    margin-left: 208px;
    margin-right: 208px
}

.my-56 {
    margin-top: 224px;
    margin-bottom: 224px
}

.mx-56 {
    margin-left: 224px;
    margin-right: 224px
}

.my-60 {
    margin-top: 240px;
    margin-bottom: 240px
}

.mx-60 {
    margin-left: 240px;
    margin-right: 240px
}

.my-64 {
    margin-top: 256px;
    margin-bottom: 256px
}

.mx-64 {
    margin-left: 256px;
    margin-right: 256px
}

.my-72 {
    margin-top: 288px;
    margin-bottom: 288px
}

.mx-72 {
    margin-left: 288px;
    margin-right: 288px
}

.my-80 {
    margin-top: 320px;
    margin-bottom: 320px
}

.mx-80 {
    margin-left: 320px;
    margin-right: 320px
}

.my-96 {
    margin-top: 384px;
    margin-bottom: 384px
}

.mx-96 {
    margin-left: 384px;
    margin-right: 384px
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-px {
    margin-top: 1px;
    margin-bottom: 1px
}

.mx-px {
    margin-left: 1px;
    margin-right: 1px
}

.my-0d5 {
    margin-top: 2px;
    margin-bottom: 2px
}

.mx-0d5 {
    margin-left: 2px;
    margin-right: 2px
}

.my-1d5 {
    margin-top: 6px;
    margin-bottom: 6px
}

.mx-1d5 {
    margin-left: 6px;
    margin-right: 6px
}

.my-2d5 {
    margin-top: 10px;
    margin-bottom: 10px
}

.mx-2d5 {
    margin-left: 10px;
    margin-right: 10px
}

.my-3d5 {
    margin-top: 14px;
    margin-bottom: 14px
}

.mx-3d5 {
    margin-left: 14px;
    margin-right: 14px
}

.-my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}

.-mx-0 {
    margin-left: 0px;
    margin-right: 0px
}

.-my-1 {
    margin-top: -4px;
    margin-bottom: -4px
}

.-mx-1 {
    margin-left: -4px;
    margin-right: -4px
}

.-my-2 {
    margin-top: -8px;
    margin-bottom: -8px
}

.-mx-2 {
    margin-left: -8px;
    margin-right: -8px
}

.-my-3 {
    margin-top: -12px;
    margin-bottom: -12px
}

.-mx-3 {
    margin-left: -12px;
    margin-right: -12px
}

.-my-4 {
    margin-top: -16px;
    margin-bottom: -16px
}

.-mx-4 {
    margin-left: -16px;
    margin-right: -16px
}

.-my-5 {
    margin-top: -20px;
    margin-bottom: -20px
}

.-mx-5 {
    margin-left: -20px;
    margin-right: -20px
}

.-my-6 {
    margin-top: -24px;
    margin-bottom: -24px
}

.-mx-6 {
    margin-left: -24px;
    margin-right: -24px
}

.-my-7 {
    margin-top: -28px;
    margin-bottom: -28px
}

.-mx-7 {
    margin-left: -28px;
    margin-right: -28px
}

.-my-8 {
    margin-top: -32px;
    margin-bottom: -32px
}

.-mx-8 {
    margin-left: -32px;
    margin-right: -32px
}

.-my-9 {
    margin-top: -36px;
    margin-bottom: -36px
}

.-mx-9 {
    margin-left: -36px;
    margin-right: -36px
}

.-my-10 {
    margin-top: -40px;
    margin-bottom: -40px
}

.-mx-10 {
    margin-left: -40px;
    margin-right: -40px
}

.-my-11 {
    margin-top: -44px;
    margin-bottom: -44px
}

.-mx-11 {
    margin-left: -44px;
    margin-right: -44px
}

.-my-12 {
    margin-top: -48px;
    margin-bottom: -48px
}

.-mx-12 {
    margin-left: -48px;
    margin-right: -48px
}

.-my-14 {
    margin-top: -56px;
    margin-bottom: -56px
}

.-mx-14 {
    margin-left: -56px;
    margin-right: -56px
}

.-my-16 {
    margin-top: -64px;
    margin-bottom: -64px
}

.-mx-16 {
    margin-left: -64px;
    margin-right: -64px
}

.-my-20 {
    margin-top: -80px;
    margin-bottom: -80px
}

.-mx-20 {
    margin-left: -80px;
    margin-right: -80px
}

.-my-24 {
    margin-top: -96px;
    margin-bottom: -96px
}

.-mx-24 {
    margin-left: -96px;
    margin-right: -96px
}

.-my-28 {
    margin-top: -112px;
    margin-bottom: -112px
}

.-mx-28 {
    margin-left: -112px;
    margin-right: -112px
}

.-my-32 {
    margin-top: -128px;
    margin-bottom: -128px
}

.-mx-32 {
    margin-left: -128px;
    margin-right: -128px
}

.-my-36 {
    margin-top: -144px;
    margin-bottom: -144px
}

.-mx-36 {
    margin-left: -144px;
    margin-right: -144px
}

.-my-40 {
    margin-top: -160px;
    margin-bottom: -160px
}

.-mx-40 {
    margin-left: -160px;
    margin-right: -160px
}

.-my-44 {
    margin-top: -176px;
    margin-bottom: -176px
}

.-mx-44 {
    margin-left: -176px;
    margin-right: -176px
}

.-my-48 {
    margin-top: -192px;
    margin-bottom: -192px
}

.-mx-48 {
    margin-left: -192px;
    margin-right: -192px
}

.-my-52 {
    margin-top: -208px;
    margin-bottom: -208px
}

.-mx-52 {
    margin-left: -208px;
    margin-right: -208px
}

.-my-56 {
    margin-top: -224px;
    margin-bottom: -224px
}

.-mx-56 {
    margin-left: -224px;
    margin-right: -224px
}

.-my-60 {
    margin-top: -240px;
    margin-bottom: -240px
}

.-mx-60 {
    margin-left: -240px;
    margin-right: -240px
}

.-my-64 {
    margin-top: -256px;
    margin-bottom: -256px
}

.-mx-64 {
    margin-left: -256px;
    margin-right: -256px
}

.-my-72 {
    margin-top: -288px;
    margin-bottom: -288px
}

.-mx-72 {
    margin-left: -288px;
    margin-right: -288px
}

.-my-80 {
    margin-top: -320px;
    margin-bottom: -320px
}

.-mx-80 {
    margin-left: -320px;
    margin-right: -320px
}

.-my-96 {
    margin-top: -384px;
    margin-bottom: -384px
}

.-mx-96 {
    margin-left: -384px;
    margin-right: -384px
}

.-my-px {
    margin-top: -1px;
    margin-bottom: -1px
}

.-mx-px {
    margin-left: -1px;
    margin-right: -1px
}

.-my-0d5 {
    margin-top: -2px;
    margin-bottom: -2px
}

.-mx-0d5 {
    margin-left: -2px;
    margin-right: -2px
}

.-my-1d5 {
    margin-top: -6px;
    margin-bottom: -6px
}

.-mx-1d5 {
    margin-left: -6px;
    margin-right: -6px
}

.-my-2d5 {
    margin-top: -10px;
    margin-bottom: -10px
}

.-mx-2d5 {
    margin-left: -10px;
    margin-right: -10px
}

.-my-3d5 {
    margin-top: -14px;
    margin-bottom: -14px
}

.-mx-3d5 {
    margin-left: -14px;
    margin-right: -14px
}

.mt-0 {
    margin-top: 0px
}

.mr-0 {
    margin-right: 0px
}

.mb-0 {
    margin-bottom: 0px
}

.ml-0 {
    margin-left: 0px
}

.mt-1 {
    margin-top: 4px
}

.mr-1 {
    margin-right: 4px
}

.mb-1 {
    margin-bottom: 4px
}

.ml-1 {
    margin-left: 4px
}

.mt-2 {
    margin-top: 8px
}

.mr-2 {
    margin-right: 8px
}

.mb-2 {
    margin-bottom: 8px
}

.ml-2 {
    margin-left: 8px
}

.mt-3 {
    margin-top: 12px
}

.mr-3 {
    margin-right: 12px
}

.mb-3 {
    margin-bottom: 12px
}

.ml-3 {
    margin-left: 12px
}

.mt-4 {
    margin-top: 16px
}

.mr-4 {
    margin-right: 16px
}

.mb-4 {
    margin-bottom: 16px
}

.ml-4 {
    margin-left: 16px
}

.mt-5 {
    margin-top: 20px
}

.mr-5 {
    margin-right: 20px
}

.mb-5 {
    margin-bottom: 20px
}

.ml-5 {
    margin-left: 20px
}

.mt-6 {
    margin-top: 24px
}

.mr-6 {
    margin-right: 24px
}

.mb-6 {
    margin-bottom: 24px
}

.ml-6 {
    margin-left: 24px
}

.mt-7 {
    margin-top: 28px
}

.mr-7 {
    margin-right: 28px
}

.mb-7 {
    margin-bottom: 28px
}

.ml-7 {
    margin-left: 28px
}

.mt-8 {
    margin-top: 32px
}

.mr-8 {
    margin-right: 32px
}

.mb-8 {
    margin-bottom: 32px
}

.ml-8 {
    margin-left: 32px
}

.mt-9 {
    margin-top: 36px
}

.mr-9 {
    margin-right: 36px
}

.mb-9 {
    margin-bottom: 36px
}

.ml-9 {
    margin-left: 36px
}

.mt-10 {
    margin-top: 40px
}

.mr-10 {
    margin-right: 40px
}

.mb-10 {
    margin-bottom: 40px
}

.ml-10 {
    margin-left: 40px
}

.mt-11 {
    margin-top: 44px
}

.mr-11 {
    margin-right: 44px
}

.mb-11 {
    margin-bottom: 44px
}

.ml-11 {
    margin-left: 44px
}

.mt-12 {
    margin-top: 48px
}

.mr-12 {
    margin-right: 48px
}

.mb-12 {
    margin-bottom: 48px
}

.ml-12 {
    margin-left: 48px
}

.mt-14 {
    margin-top: 56px
}

.mr-14 {
    margin-right: 56px
}

.mb-14 {
    margin-bottom: 56px
}

.ml-14 {
    margin-left: 56px
}

.mt-16 {
    margin-top: 64px
}

.mr-16 {
    margin-right: 64px
}

.mb-16 {
    margin-bottom: 64px
}

.ml-16 {
    margin-left: 64px
}

.mt-20 {
    margin-top: 80px
}

.mr-20 {
    margin-right: 80px
}

.mb-20 {
    margin-bottom: 80px
}

.ml-20 {
    margin-left: 80px
}

.mt-24 {
    margin-top: 96px
}

.mr-24 {
    margin-right: 96px
}

.mb-24 {
    margin-bottom: 96px
}

.ml-24 {
    margin-left: 96px
}

.mt-28 {
    margin-top: 112px
}

.mr-28 {
    margin-right: 112px
}

.mb-28 {
    margin-bottom: 112px
}

.ml-28 {
    margin-left: 112px
}

.mt-32 {
    margin-top: 128px
}

.mr-32 {
    margin-right: 128px
}

.mb-32 {
    margin-bottom: 128px
}

.ml-32 {
    margin-left: 128px
}

.mt-36 {
    margin-top: 144px
}

.mr-36 {
    margin-right: 144px
}

.mb-36 {
    margin-bottom: 144px
}

.ml-36 {
    margin-left: 144px
}

.mt-40 {
    margin-top: 160px
}

.mr-40 {
    margin-right: 160px
}

.mb-40 {
    margin-bottom: 160px
}

.ml-40 {
    margin-left: 160px
}

.mt-44 {
    margin-top: 176px
}

.mr-44 {
    margin-right: 176px
}

.mb-44 {
    margin-bottom: 176px
}

.ml-44 {
    margin-left: 176px
}

.mt-48 {
    margin-top: 192px
}

.mr-48 {
    margin-right: 192px
}

.mb-48 {
    margin-bottom: 192px
}

.ml-48 {
    margin-left: 192px
}

.mt-52 {
    margin-top: 208px
}

.mr-52 {
    margin-right: 208px
}

.mb-52 {
    margin-bottom: 208px
}

.ml-52 {
    margin-left: 208px
}

.mt-56 {
    margin-top: 224px
}

.mr-56 {
    margin-right: 224px
}

.mb-56 {
    margin-bottom: 224px
}

.ml-56 {
    margin-left: 224px
}

.mt-60 {
    margin-top: 240px
}

.mr-60 {
    margin-right: 240px
}

.mb-60 {
    margin-bottom: 240px
}

.ml-60 {
    margin-left: 240px
}

.mt-64 {
    margin-top: 256px
}

.mr-64 {
    margin-right: 256px
}

.mb-64 {
    margin-bottom: 256px
}

.ml-64 {
    margin-left: 256px
}

.mt-72 {
    margin-top: 288px
}

.mr-72 {
    margin-right: 288px
}

.mb-72 {
    margin-bottom: 288px
}

.ml-72 {
    margin-left: 288px
}

.mt-80 {
    margin-top: 320px
}

.mr-80 {
    margin-right: 320px
}

.mb-80 {
    margin-bottom: 320px
}

.ml-80 {
    margin-left: 320px
}

.mt-96 {
    margin-top: 384px
}

.mr-96 {
    margin-right: 384px
}

.mb-96 {
    margin-bottom: 384px
}

.ml-96 {
    margin-left: 384px
}

.mt-auto {
    margin-top: auto
}

.mr-auto {
    margin-right: auto
}

.mb-auto {
    margin-bottom: auto
}

.ml-auto {
    margin-left: auto
}

.mt-px {
    margin-top: 1px
}

.mr-px {
    margin-right: 1px
}

.mb-px {
    margin-bottom: 1px
}

.ml-px {
    margin-left: 1px
}

.mt-0d5 {
    margin-top: 2px
}

.mr-0d5 {
    margin-right: 2px
}

.mb-0d5 {
    margin-bottom: 2px
}

.ml-0d5 {
    margin-left: 2px
}

.mt-1d5 {
    margin-top: 6px
}

.mr-1d5 {
    margin-right: 6px
}

.mb-1d5 {
    margin-bottom: 6px
}

.ml-1d5 {
    margin-left: 6px
}

.mt-2d5 {
    margin-top: 10px
}

.mr-2d5 {
    margin-right: 10px
}

.mb-2d5 {
    margin-bottom: 10px
}

.ml-2d5 {
    margin-left: 10px
}

.mt-3d5 {
    margin-top: 14px
}

.mr-3d5 {
    margin-right: 14px
}

.mb-3d5 {
    margin-bottom: 14px
}

.ml-3d5 {
    margin-left: 14px
}

.-mt-0 {
    margin-top: 0px
}

.-mr-0 {
    margin-right: 0px
}

.-mb-0 {
    margin-bottom: 0px
}

.-ml-0 {
    margin-left: 0px
}

.-mt-1 {
    margin-top: -4px
}

.-mr-1 {
    margin-right: -4px
}

.-mb-1 {
    margin-bottom: -4px
}

.-ml-1 {
    margin-left: -4px
}

.-mt-2 {
    margin-top: -8px
}

.-mr-2 {
    margin-right: -8px
}

.-mb-2 {
    margin-bottom: -8px
}

.-ml-2 {
    margin-left: -8px
}

.-mt-3 {
    margin-top: -12px
}

.-mr-3 {
    margin-right: -12px
}

.-mb-3 {
    margin-bottom: -12px
}

.-ml-3 {
    margin-left: -12px
}

.-mt-4 {
    margin-top: -16px
}

.-mr-4 {
    margin-right: -16px
}

.-mb-4 {
    margin-bottom: -16px
}

.-ml-4 {
    margin-left: -16px
}

.-mt-5 {
    margin-top: -20px
}

.-mr-5 {
    margin-right: -20px
}

.-mb-5 {
    margin-bottom: -20px
}

.-ml-5 {
    margin-left: -20px
}

.-mt-6 {
    margin-top: -24px
}

.-mr-6 {
    margin-right: -24px
}

.-mb-6 {
    margin-bottom: -24px
}

.-ml-6 {
    margin-left: -24px
}

.-mt-7 {
    margin-top: -28px
}

.-mr-7 {
    margin-right: -28px
}

.-mb-7 {
    margin-bottom: -28px
}

.-ml-7 {
    margin-left: -28px
}

.-mt-8 {
    margin-top: -32px
}

.-mr-8 {
    margin-right: -32px
}

.-mb-8 {
    margin-bottom: -32px
}

.-ml-8 {
    margin-left: -32px
}

.-mt-9 {
    margin-top: -36px
}

.-mr-9 {
    margin-right: -36px
}

.-mb-9 {
    margin-bottom: -36px
}

.-ml-9 {
    margin-left: -36px
}

.-mt-10 {
    margin-top: -40px
}

.-mr-10 {
    margin-right: -40px
}

.-mb-10 {
    margin-bottom: -40px
}

.-ml-10 {
    margin-left: -40px
}

.-mt-11 {
    margin-top: -44px
}

.-mr-11 {
    margin-right: -44px
}

.-mb-11 {
    margin-bottom: -44px
}

.-ml-11 {
    margin-left: -44px
}

.-mt-12 {
    margin-top: -48px
}

.-mr-12 {
    margin-right: -48px
}

.-mb-12 {
    margin-bottom: -48px
}

.-ml-12 {
    margin-left: -48px
}

.-mt-14 {
    margin-top: -56px
}

.-mr-14 {
    margin-right: -56px
}

.-mb-14 {
    margin-bottom: -56px
}

.-ml-14 {
    margin-left: -56px
}

.-mt-16 {
    margin-top: -64px
}

.-mr-16 {
    margin-right: -64px
}

.-mb-16 {
    margin-bottom: -64px
}

.-ml-16 {
    margin-left: -64px
}

.-mt-20 {
    margin-top: -80px
}

.-mr-20 {
    margin-right: -80px
}

.-mb-20 {
    margin-bottom: -80px
}

.-ml-20 {
    margin-left: -80px
}

.-mt-24 {
    margin-top: -96px
}

.-mr-24 {
    margin-right: -96px
}

.-mb-24 {
    margin-bottom: -96px
}

.-ml-24 {
    margin-left: -96px
}

.-mt-28 {
    margin-top: -112px
}

.-mr-28 {
    margin-right: -112px
}

.-mb-28 {
    margin-bottom: -112px
}

.-ml-28 {
    margin-left: -112px
}

.-mt-32 {
    margin-top: -128px
}

.-mr-32 {
    margin-right: -128px
}

.-mb-32 {
    margin-bottom: -128px
}

.-ml-32 {
    margin-left: -128px
}

.-mt-36 {
    margin-top: -144px
}

.-mr-36 {
    margin-right: -144px
}

.-mb-36 {
    margin-bottom: -144px
}

.-ml-36 {
    margin-left: -144px
}

.-mt-40 {
    margin-top: -160px
}

.-mr-40 {
    margin-right: -160px
}

.-mb-40 {
    margin-bottom: -160px
}

.-ml-40 {
    margin-left: -160px
}

.-mt-44 {
    margin-top: -176px
}

.-mr-44 {
    margin-right: -176px
}

.-mb-44 {
    margin-bottom: -176px
}

.-ml-44 {
    margin-left: -176px
}

.-mt-48 {
    margin-top: -192px
}

.-mr-48 {
    margin-right: -192px
}

.-mb-48 {
    margin-bottom: -192px
}

.-ml-48 {
    margin-left: -192px
}

.-mt-52 {
    margin-top: -208px
}

.-mr-52 {
    margin-right: -208px
}

.-mb-52 {
    margin-bottom: -208px
}

.-ml-52 {
    margin-left: -208px
}

.-mt-56 {
    margin-top: -224px
}

.-mr-56 {
    margin-right: -224px
}

.-mb-56 {
    margin-bottom: -224px
}

.-ml-56 {
    margin-left: -224px
}

.-mt-60 {
    margin-top: -240px
}

.-mr-60 {
    margin-right: -240px
}

.-mb-60 {
    margin-bottom: -240px
}

.-ml-60 {
    margin-left: -240px
}

.-mt-64 {
    margin-top: -256px
}

.-mr-64 {
    margin-right: -256px
}

.-mb-64 {
    margin-bottom: -256px
}

.-ml-64 {
    margin-left: -256px
}

.-mt-72 {
    margin-top: -288px
}

.-mr-72 {
    margin-right: -288px
}

.-mb-72 {
    margin-bottom: -288px
}

.-ml-72 {
    margin-left: -288px
}

.-mt-80 {
    margin-top: -320px
}

.-mr-80 {
    margin-right: -320px
}

.-mb-80 {
    margin-bottom: -320px
}

.-ml-80 {
    margin-left: -320px
}

.-mt-96 {
    margin-top: -384px
}

.-mr-96 {
    margin-right: -384px
}

.-mb-96 {
    margin-bottom: -384px
}

.-ml-96 {
    margin-left: -384px
}

.-mt-px {
    margin-top: -1px
}

.-mr-px {
    margin-right: -1px
}

.-mb-px {
    margin-bottom: -1px
}

.-ml-px {
    margin-left: -1px
}

.-mt-0d5 {
    margin-top: -2px
}

.-mr-0d5 {
    margin-right: -2px
}

.-mb-0d5 {
    margin-bottom: -2px
}

.-ml-0d5 {
    margin-left: -2px
}

.-mt-1d5 {
    margin-top: -6px
}

.-mr-1d5 {
    margin-right: -6px
}

.-mb-1d5 {
    margin-bottom: -6px
}

.-ml-1d5 {
    margin-left: -6px
}

.-mt-2d5 {
    margin-top: -10px
}

.-mr-2d5 {
    margin-right: -10px
}

.-mb-2d5 {
    margin-bottom: -10px
}

.-ml-2d5 {
    margin-left: -10px
}

.-mt-3d5 {
    margin-top: -14px
}

.-mr-3d5 {
    margin-right: -14px
}

.-mb-3d5 {
    margin-bottom: -14px
}

.-ml-3d5 {
    margin-left: -14px
}

.max-h-0 {
    max-height: 0px
}

.max-h-1 {
    max-height: 4px
}

.max-h-2 {
    max-height: 8px
}

.max-h-3 {
    max-height: 12px
}

.max-h-4 {
    max-height: 16px
}

.max-h-5 {
    max-height: 20px
}

.max-h-6 {
    max-height: 24px
}

.max-h-7 {
    max-height: 28px
}

.max-h-8 {
    max-height: 32px
}

.max-h-9 {
    max-height: 36px
}

.max-h-10 {
    max-height: 40px
}

.max-h-11 {
    max-height: 44px
}

.max-h-12 {
    max-height: 48px
}

.max-h-14 {
    max-height: 56px
}

.max-h-16 {
    max-height: 64px
}

.max-h-20 {
    max-height: 80px
}

.max-h-24 {
    max-height: 96px
}

.max-h-28 {
    max-height: 112px
}

.max-h-32 {
    max-height: 128px
}

.max-h-36 {
    max-height: 144px
}

.max-h-40 {
    max-height: 160px
}

.max-h-44 {
    max-height: 176px
}

.max-h-48 {
    max-height: 192px
}

.max-h-52 {
    max-height: 208px
}

.max-h-56 {
    max-height: 224px
}

.max-h-60 {
    max-height: 240px
}

.max-h-64 {
    max-height: 256px
}

.max-h-72 {
    max-height: 288px
}

.max-h-80 {
    max-height: 320px
}

.max-h-96 {
    max-height: 384px
}

.max-h-px {
    max-height: 1px
}

.max-h-0d5 {
    max-height: 2px
}

.max-h-1d5 {
    max-height: 6px
}

.max-h-2d5 {
    max-height: 10px
}

.max-h-3d5 {
    max-height: 14px
}

.max-h-full {
    max-height: 100%
}

.max-h-screen {
    max-height: 100vh
}

.max-w-0 {
    max-width: 0px
}

.max-w-none {
    max-width: none
}

.max-w-xs {
    max-width: 320px
}

.max-w-sm {
    max-width: 384px
}

.max-w-md {
    max-width: 448px
}

.max-w-lg {
    max-width: 512px
}

.max-w-xl {
    max-width: 576px
}

.max-w-2xl {
    max-width: 672px
}

.max-w-3xl {
    max-width: 768px
}

.max-w-4xl {
    max-width: 896px
}

.max-w-5xl {
    max-width: 1024px
}

.max-w-6xl {
    max-width: 1152px
}

.max-w-7xl {
    max-width: 1280px
}

.max-w-full {
    max-width: 100%
}

.max-w-min {
    max-width: min-content
}

.max-w-max {
    max-width: max-content
}

.max-w-prose {
    max-width: 65ch
}

.max-w-screen-sm {
    max-width: 640px
}

.max-w-screen-md {
    max-width: 768px
}

.max-w-screen-lg {
    max-width: 1024px
}

.max-w-screen-xl {
    max-width: 1280px
}

.max-w-screen-2xl {
    max-width: 1536px
}

.min-h-0 {
    min-height: 0px
}

.min-h-full {
    min-height: 100%
}

.min-h-screen {
    min-height: 100vh
}

.min-w-0 {
    min-width: 0px
}

.min-w-full {
    min-width: 100%
}

.min-w-min {
    min-width: min-content
}

.min-w-max {
    min-width: max-content
}

.object-contain {
    object-fit: contain
}

.object-cover {
    object-fit: cover
}

.object-fill {
    object-fit: fill
}

.object-none {
    object-fit: none
}

.object-scale-down {
    object-fit: scale-down
}

.object-bottom {
    object-position: bottom
}

.object-center {
    object-position: center
}

.object-left {
    object-position: left
}

.object-left-bottom {
    object-position: left bottom
}

.object-left-top {
    object-position: left top
}

.object-right {
    object-position: right
}

.object-right-bottom {
    object-position: right bottom
}

.object-right-top {
    object-position: right top
}

.object-top {
    object-position: top
}

.opacity-0 {
    opacity: 0
}

.opacity-5 {
    opacity: 0.05
}

.opacity-10 {
    opacity: 0.1
}

.opacity-20 {
    opacity: 0.2
}

.opacity-25 {
    opacity: 0.25
}

.opacity-30 {
    opacity: 0.3
}

.opacity-40 {
    opacity: 0.4
}

.opacity-50 {
    opacity: 0.5
}

.opacity-60 {
    opacity: 0.6
}

.opacity-70 {
    opacity: 0.7
}

.opacity-75 {
    opacity: 0.75
}

.opacity-80 {
    opacity: 0.8
}

.opacity-90 {
    opacity: 0.9
}

.opacity-95 {
    opacity: 0.95
}

.opacity-100 {
    opacity: 1
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
}

.outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
}

.overflow-auto {
    overflow: auto
}

.overflow-hidden {
    overflow: hidden
}

.overflow-visible {
    overflow: visible
}

.overflow-scroll {
    overflow: scroll
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-hidden {
    overflow-x: hidden
}

.overflow-y-hidden {
    overflow-y: hidden
}

.overflow-x-visible {
    overflow-x: visible
}

.overflow-y-visible {
    overflow-y: visible
}

.overflow-x-scroll {
    overflow-x: scroll
}

.overflow-y-scroll {
    overflow-y: scroll
}

.overscroll-auto {
    overscroll-behavior: auto
}

.overscroll-contain {
    overscroll-behavior: contain
}

.overscroll-none {
    overscroll-behavior: none
}

.overscroll-y-auto {
    overscroll-behavior-y: auto
}

.overscroll-y-contain {
    overscroll-behavior-y: contain
}

.overscroll-y-none {
    overscroll-behavior-y: none
}

.overscroll-x-auto {
    overscroll-behavior-x: auto
}

.overscroll-x-contain {
    overscroll-behavior-x: contain
}

.overscroll-x-none {
    overscroll-behavior-x: none
}

.p-0 {
    padding: 0px
}

.p-1 {
    padding: 4px
}

.p-2 {
    padding: 8px
}

.p-3 {
    padding: 12px
}

.p-4 {
    padding: 16px
}

.p-5 {
    padding: 20px
}

.p-6 {
    padding: 24px
}

.p-7 {
    padding: 28px
}

.p-8 {
    padding: 32px
}

.p-9 {
    padding: 36px
}

.p-10 {
    padding: 40px
}

.p-11 {
    padding: 44px
}

.p-12 {
    padding: 48px
}

.p-14 {
    padding: 56px
}

.p-16 {
    padding: 64px
}

.p-20 {
    padding: 80px
}

.p-24 {
    padding: 96px
}

.p-28 {
    padding: 112px
}

.p-32 {
    padding: 128px
}

.p-36 {
    padding: 144px
}

.p-40 {
    padding: 160px
}

.p-44 {
    padding: 176px
}

.p-48 {
    padding: 192px
}

.p-52 {
    padding: 208px
}

.p-56 {
    padding: 224px
}

.p-60 {
    padding: 240px
}

.p-64 {
    padding: 256px
}

.p-72 {
    padding: 288px
}

.p-80 {
    padding: 320px
}

.p-96 {
    padding: 384px
}

.p-px {
    padding: 1px
}

.p-0d5 {
    padding: 2px
}

.p-1d5 {
    padding: 6px
}

.p-2d5 {
    padding: 10px
}

.p-3d5 {
    padding: 14px
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px
}

.px-0 {
    padding-left: 0px;
    padding-right: 0px
}

.py-1 {
    padding-top: 4px;
    padding-bottom: 4px
}

.px-1 {
    padding-left: 4px;
    padding-right: 4px
}

.py-2 {
    padding-top: 8px;
    padding-bottom: 8px
}

.px-2 {
    padding-left: 8px;
    padding-right: 8px
}

.py-3 {
    padding-top: 12px;
    padding-bottom: 12px
}

.px-3 {
    padding-left: 12px;
    padding-right: 12px
}

.py-4 {
    padding-top: 16px;
    padding-bottom: 16px
}

.px-4 {
    padding-left: 16px;
    padding-right: 16px
}

.py-5 {
    padding-top: 20px;
    padding-bottom: 20px
}

.px-5 {
    padding-left: 20px;
    padding-right: 20px
}

.py-6 {
    padding-top: 24px;
    padding-bottom: 24px
}

.px-6 {
    padding-left: 24px;
    padding-right: 24px
}

.py-7 {
    padding-top: 28px;
    padding-bottom: 28px
}

.px-7 {
    padding-left: 28px;
    padding-right: 28px
}

.py-8 {
    padding-top: 32px;
    padding-bottom: 32px
}

.px-8 {
    padding-left: 32px;
    padding-right: 32px
}

.py-9 {
    padding-top: 36px;
    padding-bottom: 36px
}

.px-9 {
    padding-left: 36px;
    padding-right: 36px
}

.py-10 {
    padding-top: 40px;
    padding-bottom: 40px
}

.px-10 {
    padding-left: 40px;
    padding-right: 40px
}

.py-11 {
    padding-top: 44px;
    padding-bottom: 44px
}

.px-11 {
    padding-left: 44px;
    padding-right: 44px
}

.py-12 {
    padding-top: 48px;
    padding-bottom: 48px
}

.px-12 {
    padding-left: 48px;
    padding-right: 48px
}

.py-14 {
    padding-top: 56px;
    padding-bottom: 56px
}

.px-14 {
    padding-left: 56px;
    padding-right: 56px
}

.py-16 {
    padding-top: 64px;
    padding-bottom: 64px
}

.px-16 {
    padding-left: 64px;
    padding-right: 64px
}

.py-20 {
    padding-top: 80px;
    padding-bottom: 80px
}

.px-20 {
    padding-left: 80px;
    padding-right: 80px
}

.py-24 {
    padding-top: 96px;
    padding-bottom: 96px
}

.px-24 {
    padding-left: 96px;
    padding-right: 96px
}

.py-28 {
    padding-top: 112px;
    padding-bottom: 112px
}

.px-28 {
    padding-left: 112px;
    padding-right: 112px
}

.py-32 {
    padding-top: 128px;
    padding-bottom: 128px
}

.px-32 {
    padding-left: 128px;
    padding-right: 128px
}

.py-36 {
    padding-top: 144px;
    padding-bottom: 144px
}

.px-36 {
    padding-left: 144px;
    padding-right: 144px
}

.py-40 {
    padding-top: 160px;
    padding-bottom: 160px
}

.px-40 {
    padding-left: 160px;
    padding-right: 160px
}

.py-44 {
    padding-top: 176px;
    padding-bottom: 176px
}

.px-44 {
    padding-left: 176px;
    padding-right: 176px
}

.py-48 {
    padding-top: 192px;
    padding-bottom: 192px
}

.px-48 {
    padding-left: 192px;
    padding-right: 192px
}

.py-52 {
    padding-top: 208px;
    padding-bottom: 208px
}

.px-52 {
    padding-left: 208px;
    padding-right: 208px
}

.py-56 {
    padding-top: 224px;
    padding-bottom: 224px
}

.px-56 {
    padding-left: 224px;
    padding-right: 224px
}

.py-60 {
    padding-top: 240px;
    padding-bottom: 240px
}

.px-60 {
    padding-left: 240px;
    padding-right: 240px
}

.py-64 {
    padding-top: 256px;
    padding-bottom: 256px
}

.px-64 {
    padding-left: 256px;
    padding-right: 256px
}

.py-72 {
    padding-top: 288px;
    padding-bottom: 288px
}

.px-72 {
    padding-left: 288px;
    padding-right: 288px
}

.py-80 {
    padding-top: 320px;
    padding-bottom: 320px
}

.px-80 {
    padding-left: 320px;
    padding-right: 320px
}

.py-96 {
    padding-top: 384px;
    padding-bottom: 384px
}

.px-96 {
    padding-left: 384px;
    padding-right: 384px
}

.py-px {
    padding-top: 1px;
    padding-bottom: 1px
}

.px-px {
    padding-left: 1px;
    padding-right: 1px
}

.py-0d5 {
    padding-top: 2px;
    padding-bottom: 2px
}

.px-0d5 {
    padding-left: 2px;
    padding-right: 2px
}

.py-1d5 {
    padding-top: 6px;
    padding-bottom: 6px
}

.px-1d5 {
    padding-left: 6px;
    padding-right: 6px
}

.py-2d5 {
    padding-top: 10px;
    padding-bottom: 10px
}

.px-2d5 {
    padding-left: 10px;
    padding-right: 10px
}

.py-3d5 {
    padding-top: 14px;
    padding-bottom: 14px
}

.px-3d5 {
    padding-left: 14px;
    padding-right: 14px
}

.pt-0 {
    padding-top: 0px
}

.pr-0 {
    padding-right: 0px
}

.pb-0 {
    padding-bottom: 0px
}

.pl-0 {
    padding-left: 0px
}

.pt-1 {
    padding-top: 4px
}

.pr-1 {
    padding-right: 4px
}

.pb-1 {
    padding-bottom: 4px
}

.pl-1 {
    padding-left: 4px
}

.pt-2 {
    padding-top: 8px
}

.pr-2 {
    padding-right: 8px
}

.pb-2 {
    padding-bottom: 8px
}

.pl-2 {
    padding-left: 8px
}

.pt-3 {
    padding-top: 12px
}

.pr-3 {
    padding-right: 12px
}

.pb-3 {
    padding-bottom: 12px
}

.pl-3 {
    padding-left: 12px
}

.pt-4 {
    padding-top: 16px
}

.pr-4 {
    padding-right: 16px
}

.pb-4 {
    padding-bottom: 16px
}

.pl-4 {
    padding-left: 16px
}

.pt-5 {
    padding-top: 20px
}

.pr-5 {
    padding-right: 20px
}

.pb-5 {
    padding-bottom: 20px
}

.pl-5 {
    padding-left: 20px
}

.pt-6 {
    padding-top: 24px
}

.pr-6 {
    padding-right: 24px
}

.pb-6 {
    padding-bottom: 24px
}

.pl-6 {
    padding-left: 24px
}

.pt-7 {
    padding-top: 28px
}

.pr-7 {
    padding-right: 28px
}

.pb-7 {
    padding-bottom: 28px
}

.pl-7 {
    padding-left: 28px
}

.pt-8 {
    padding-top: 32px
}

.pr-8 {
    padding-right: 32px
}

.pb-8 {
    padding-bottom: 32px
}

.pl-8 {
    padding-left: 32px
}

.pt-9 {
    padding-top: 36px
}

.pr-9 {
    padding-right: 36px
}

.pb-9 {
    padding-bottom: 36px
}

.pl-9 {
    padding-left: 36px
}

.pt-10 {
    padding-top: 40px
}

.pr-10 {
    padding-right: 40px
}

.pb-10 {
    padding-bottom: 40px
}

.pl-10 {
    padding-left: 40px
}

.pt-11 {
    padding-top: 44px
}

.pr-11 {
    padding-right: 44px
}

.pb-11 {
    padding-bottom: 44px
}

.pl-11 {
    padding-left: 44px
}

.pt-12 {
    padding-top: 48px
}

.pr-12 {
    padding-right: 48px
}

.pb-12 {
    padding-bottom: 48px
}

.pl-12 {
    padding-left: 48px
}

.pt-14 {
    padding-top: 56px
}

.pr-14 {
    padding-right: 56px
}

.pb-14 {
    padding-bottom: 56px
}

.pl-14 {
    padding-left: 56px
}

.pt-16 {
    padding-top: 64px
}

.pr-16 {
    padding-right: 64px
}

.pb-16 {
    padding-bottom: 64px
}

.pl-16 {
    padding-left: 64px
}

.pt-20 {
    padding-top: 80px
}

.pr-20 {
    padding-right: 80px
}

.pb-20 {
    padding-bottom: 80px
}

.pl-20 {
    padding-left: 80px
}

.pt-24 {
    padding-top: 96px
}

.pr-24 {
    padding-right: 96px
}

.pb-24 {
    padding-bottom: 96px
}

.pl-24 {
    padding-left: 96px
}

.pt-28 {
    padding-top: 112px
}

.pr-28 {
    padding-right: 112px
}

.pb-28 {
    padding-bottom: 112px
}

.pl-28 {
    padding-left: 112px
}

.pt-32 {
    padding-top: 128px
}

.pr-32 {
    padding-right: 128px
}

.pb-32 {
    padding-bottom: 128px
}

.pl-32 {
    padding-left: 128px
}

.pt-36 {
    padding-top: 144px
}

.pr-36 {
    padding-right: 144px
}

.pb-36 {
    padding-bottom: 144px
}

.pl-36 {
    padding-left: 144px
}

.pt-40 {
    padding-top: 160px
}

.pr-40 {
    padding-right: 160px
}

.pb-40 {
    padding-bottom: 160px
}

.pl-40 {
    padding-left: 160px
}

.pt-44 {
    padding-top: 176px
}

.pr-44 {
    padding-right: 176px
}

.pb-44 {
    padding-bottom: 176px
}

.pl-44 {
    padding-left: 176px
}

.pt-48 {
    padding-top: 192px
}

.pr-48 {
    padding-right: 192px
}

.pb-48 {
    padding-bottom: 192px
}

.pl-48 {
    padding-left: 192px
}

.pt-52 {
    padding-top: 208px
}

.pr-52 {
    padding-right: 208px
}

.pb-52 {
    padding-bottom: 208px
}

.pl-52 {
    padding-left: 208px
}

.pt-56 {
    padding-top: 224px
}

.pr-56 {
    padding-right: 224px
}

.pb-56 {
    padding-bottom: 224px
}

.pl-56 {
    padding-left: 224px
}

.pt-60 {
    padding-top: 240px
}

.pr-60 {
    padding-right: 240px
}

.pb-60 {
    padding-bottom: 240px
}

.pl-60 {
    padding-left: 240px
}

.pt-64 {
    padding-top: 256px
}

.pr-64 {
    padding-right: 256px
}

.pb-64 {
    padding-bottom: 256px
}

.pl-64 {
    padding-left: 256px
}

.pt-72 {
    padding-top: 288px
}

.pr-72 {
    padding-right: 288px
}

.pb-72 {
    padding-bottom: 288px
}

.pl-72 {
    padding-left: 288px
}

.pt-80 {
    padding-top: 320px
}

.pr-80 {
    padding-right: 320px
}

.pb-80 {
    padding-bottom: 320px
}

.pl-80 {
    padding-left: 320px
}

.pt-96 {
    padding-top: 384px
}

.pr-96 {
    padding-right: 384px
}

.pb-96 {
    padding-bottom: 384px
}

.pl-96 {
    padding-left: 384px
}

.pt-px {
    padding-top: 1px
}

.pr-px {
    padding-right: 1px
}

.pb-px {
    padding-bottom: 1px
}

.pl-px {
    padding-left: 1px
}

.pt-0d5 {
    padding-top: 2px
}

.pr-0d5 {
    padding-right: 2px
}

.pb-0d5 {
    padding-bottom: 2px
}

.pl-0d5 {
    padding-left: 2px
}

.pt-1d5 {
    padding-top: 6px
}

.pr-1d5 {
    padding-right: 6px
}

.pb-1d5 {
    padding-bottom: 6px
}

.pl-1d5 {
    padding-left: 6px
}

.pt-2d5 {
    padding-top: 10px
}

.pr-2d5 {
    padding-right: 10px
}

.pb-2d5 {
    padding-bottom: 10px
}

.pl-2d5 {
    padding-left: 10px
}

.pt-3d5 {
    padding-top: 14px
}

.pr-3d5 {
    padding-right: 14px
}

.pb-3d5 {
    padding-bottom: 14px
}

.pl-3d5 {
    padding-left: 14px
}

.placeholder-transparent {
    placeholder-color: transparent
}

.placeholder-current {
    placeholder-color: currentColor
}

.placeholder-black {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
}

.placeholder-white {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
}

.placeholder-gray-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
}

.placeholder-gray-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
}

.placeholder-gray-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
}

.placeholder-gray-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
}

.placeholder-gray-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}

.placeholder-gray-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
}

.placeholder-gray-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
}

.placeholder-gray-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
}

.placeholder-gray-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
}

.placeholder-gray-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
}

.placeholder-red-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
}

.placeholder-red-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
}

.placeholder-red-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
}

.placeholder-red-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
}

.placeholder-red-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
}

.placeholder-red-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
}

.placeholder-red-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
}

.placeholder-red-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
}

.placeholder-red-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
}

.placeholder-red-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
}

.placeholder-yellow-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
}

.placeholder-yellow-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
}

.placeholder-yellow-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
}

.placeholder-yellow-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
}

.placeholder-yellow-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
}

.placeholder-yellow-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
}

.placeholder-yellow-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
}

.placeholder-yellow-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
}

.placeholder-yellow-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
}

.placeholder-yellow-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
}

.placeholder-green-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
}

.placeholder-green-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
}

.placeholder-green-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
}

.placeholder-green-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
}

.placeholder-green-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
}

.placeholder-green-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
}

.placeholder-green-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
}

.placeholder-green-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
}

.placeholder-green-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
}

.placeholder-green-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
}

.placeholder-blue-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
}

.placeholder-blue-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
}

.placeholder-blue-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
}

.placeholder-blue-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
}

.placeholder-blue-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
}

.placeholder-blue-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
}

.placeholder-blue-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
}

.placeholder-blue-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
}

.placeholder-blue-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
}

.placeholder-blue-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
}

.placeholder-indigo-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
}

.placeholder-indigo-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
}

.placeholder-indigo-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
}

.placeholder-indigo-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
}

.placeholder-indigo-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
}

.placeholder-indigo-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
}

.placeholder-indigo-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
}

.placeholder-indigo-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
}

.placeholder-indigo-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
}

.placeholder-indigo-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
}

.placeholder-purple-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
}

.placeholder-purple-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
}

.placeholder-purple-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
}

.placeholder-purple-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
}

.placeholder-purple-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
}

.placeholder-purple-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
}

.placeholder-purple-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
}

.placeholder-purple-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
}

.placeholder-purple-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
}

.placeholder-purple-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
}

.placeholder-pink-50 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
}

.placeholder-pink-100 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
}

.placeholder-pink-200 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
}

.placeholder-pink-300 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
}

.placeholder-pink-400 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
}

.placeholder-pink-500 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
}

.placeholder-pink-600 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
}

.placeholder-pink-700 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
}

.placeholder-pink-800 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
}

.placeholder-pink-900 {
    --tw-placeholder-opacity: 1;
    placeholder-color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
}

.placeholder-opacity-0 {
    --tw-placeholder-opacity: 0
}

.placeholder-opacity-5 {
    --tw-placeholder-opacity: 0.05
}

.placeholder-opacity-10 {
    --tw-placeholder-opacity: 0.1
}

.placeholder-opacity-20 {
    --tw-placeholder-opacity: 0.2
}

.placeholder-opacity-25 {
    --tw-placeholder-opacity: 0.25
}

.placeholder-opacity-30 {
    --tw-placeholder-opacity: 0.3
}

.placeholder-opacity-40 {
    --tw-placeholder-opacity: 0.4
}

.placeholder-opacity-50 {
    --tw-placeholder-opacity: 0.5
}

.placeholder-opacity-60 {
    --tw-placeholder-opacity: 0.6
}

.placeholder-opacity-70 {
    --tw-placeholder-opacity: 0.7
}

.placeholder-opacity-75 {
    --tw-placeholder-opacity: 0.75
}

.placeholder-opacity-80 {
    --tw-placeholder-opacity: 0.8
}

.placeholder-opacity-90 {
    --tw-placeholder-opacity: 0.9
}

.placeholder-opacity-95 {
    --tw-placeholder-opacity: 0.95
}

.placeholder-opacity-100 {
    --tw-placeholder-opacity: 1
}

.pointer-events-none {
    pointer-events: none
}

.pointer-events-auto {
    pointer-events: auto
}

.static {
    position: static
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: sticky
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.inset-1 {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px
}

.inset-2 {
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px
}

.inset-3 {
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px
}

.inset-4 {
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 16px
}

.inset-5 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px
}

.inset-6 {
    top: 24px;
    right: 24px;
    bottom: 24px;
    left: 24px
}

.inset-7 {
    top: 28px;
    right: 28px;
    bottom: 28px;
    left: 28px
}

.inset-8 {
    top: 32px;
    right: 32px;
    bottom: 32px;
    left: 32px
}

.inset-9 {
    top: 36px;
    right: 36px;
    bottom: 36px;
    left: 36px
}

.inset-10 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px
}

.inset-11 {
    top: 44px;
    right: 44px;
    bottom: 44px;
    left: 44px
}

.inset-12 {
    top: 48px;
    right: 48px;
    bottom: 48px;
    left: 48px
}

.inset-14 {
    top: 56px;
    right: 56px;
    bottom: 56px;
    left: 56px
}

.inset-16 {
    top: 64px;
    right: 64px;
    bottom: 64px;
    left: 64px
}

.inset-20 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px
}

.inset-24 {
    top: 96px;
    right: 96px;
    bottom: 96px;
    left: 96px
}

.inset-28 {
    top: 112px;
    right: 112px;
    bottom: 112px;
    left: 112px
}

.inset-32 {
    top: 128px;
    right: 128px;
    bottom: 128px;
    left: 128px
}

.inset-36 {
    top: 144px;
    right: 144px;
    bottom: 144px;
    left: 144px
}

.inset-40 {
    top: 160px;
    right: 160px;
    bottom: 160px;
    left: 160px
}

.inset-44 {
    top: 176px;
    right: 176px;
    bottom: 176px;
    left: 176px
}

.inset-48 {
    top: 192px;
    right: 192px;
    bottom: 192px;
    left: 192px
}

.inset-52 {
    top: 208px;
    right: 208px;
    bottom: 208px;
    left: 208px
}

.inset-56 {
    top: 224px;
    right: 224px;
    bottom: 224px;
    left: 224px
}

.inset-60 {
    top: 240px;
    right: 240px;
    bottom: 240px;
    left: 240px
}

.inset-64 {
    top: 256px;
    right: 256px;
    bottom: 256px;
    left: 256px
}

.inset-72 {
    top: 288px;
    right: 288px;
    bottom: 288px;
    left: 288px
}

.inset-80 {
    top: 320px;
    right: 320px;
    bottom: 320px;
    left: 320px
}

.inset-96 {
    top: 384px;
    right: 384px;
    bottom: 384px;
    left: 384px
}

.inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
}

.inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
}

.inset-0d5 {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px
}

.inset-1d5 {
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px
}

.inset-2d5 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px
}

.inset-3d5 {
    top: 14px;
    right: 14px;
    bottom: 14px;
    left: 14px
}

.-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.-inset-1 {
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px
}

.-inset-2 {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.-inset-3 {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px
}

.-inset-4 {
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px
}

.-inset-5 {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px
}

.-inset-6 {
    top: -24px;
    right: -24px;
    bottom: -24px;
    left: -24px
}

.-inset-7 {
    top: -28px;
    right: -28px;
    bottom: -28px;
    left: -28px
}

.-inset-8 {
    top: -32px;
    right: -32px;
    bottom: -32px;
    left: -32px
}

.-inset-9 {
    top: -36px;
    right: -36px;
    bottom: -36px;
    left: -36px
}

.-inset-10 {
    top: -40px;
    right: -40px;
    bottom: -40px;
    left: -40px
}

.-inset-11 {
    top: -44px;
    right: -44px;
    bottom: -44px;
    left: -44px
}

.-inset-12 {
    top: -48px;
    right: -48px;
    bottom: -48px;
    left: -48px
}

.-inset-14 {
    top: -56px;
    right: -56px;
    bottom: -56px;
    left: -56px
}

.-inset-16 {
    top: -64px;
    right: -64px;
    bottom: -64px;
    left: -64px
}

.-inset-20 {
    top: -80px;
    right: -80px;
    bottom: -80px;
    left: -80px
}

.-inset-24 {
    top: -96px;
    right: -96px;
    bottom: -96px;
    left: -96px
}

.-inset-28 {
    top: -112px;
    right: -112px;
    bottom: -112px;
    left: -112px
}

.-inset-32 {
    top: -128px;
    right: -128px;
    bottom: -128px;
    left: -128px
}

.-inset-36 {
    top: -144px;
    right: -144px;
    bottom: -144px;
    left: -144px
}

.-inset-40 {
    top: -160px;
    right: -160px;
    bottom: -160px;
    left: -160px
}

.-inset-44 {
    top: -176px;
    right: -176px;
    bottom: -176px;
    left: -176px
}

.-inset-48 {
    top: -192px;
    right: -192px;
    bottom: -192px;
    left: -192px
}

.-inset-52 {
    top: -208px;
    right: -208px;
    bottom: -208px;
    left: -208px
}

.-inset-56 {
    top: -224px;
    right: -224px;
    bottom: -224px;
    left: -224px
}

.-inset-60 {
    top: -240px;
    right: -240px;
    bottom: -240px;
    left: -240px
}

.-inset-64 {
    top: -256px;
    right: -256px;
    bottom: -256px;
    left: -256px
}

.-inset-72 {
    top: -288px;
    right: -288px;
    bottom: -288px;
    left: -288px
}

.-inset-80 {
    top: -320px;
    right: -320px;
    bottom: -320px;
    left: -320px
}

.-inset-96 {
    top: -384px;
    right: -384px;
    bottom: -384px;
    left: -384px
}

.-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
}

.-inset-0d5 {
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px
}

.-inset-1d5 {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px
}

.-inset-2d5 {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px
}

.-inset-3d5 {
    top: -14px;
    right: -14px;
    bottom: -14px;
    left: -14px
}

.inset-1_2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
}

.inset-1_3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
}

.inset-2_3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
}

.inset-1_4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
}

.inset-2_4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
}

.inset-3_4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
}

.inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
}

.-inset-1_2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
}

.-inset-1_3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
}

.-inset-2_3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
}

.-inset-1_4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
}

.-inset-2_4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
}

.-inset-3_4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
}

.-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
}

.inset-y-0 {
    top: 0px;
    bottom: 0px
}

.inset-x-0 {
    right: 0px;
    left: 0px
}

.inset-y-1 {
    top: 4px;
    bottom: 4px
}

.inset-x-1 {
    right: 4px;
    left: 4px
}

.inset-y-2 {
    top: 8px;
    bottom: 8px
}

.inset-x-2 {
    right: 8px;
    left: 8px
}

.inset-y-3 {
    top: 12px;
    bottom: 12px
}

.inset-x-3 {
    right: 12px;
    left: 12px
}

.inset-y-4 {
    top: 16px;
    bottom: 16px
}

.inset-x-4 {
    right: 16px;
    left: 16px
}

.inset-y-5 {
    top: 20px;
    bottom: 20px
}

.inset-x-5 {
    right: 20px;
    left: 20px
}

.inset-y-6 {
    top: 24px;
    bottom: 24px
}

.inset-x-6 {
    right: 24px;
    left: 24px
}

.inset-y-7 {
    top: 28px;
    bottom: 28px
}

.inset-x-7 {
    right: 28px;
    left: 28px
}

.inset-y-8 {
    top: 32px;
    bottom: 32px
}

.inset-x-8 {
    right: 32px;
    left: 32px
}

.inset-y-9 {
    top: 36px;
    bottom: 36px
}

.inset-x-9 {
    right: 36px;
    left: 36px
}

.inset-y-10 {
    top: 40px;
    bottom: 40px
}

.inset-x-10 {
    right: 40px;
    left: 40px
}

.inset-y-11 {
    top: 44px;
    bottom: 44px
}

.inset-x-11 {
    right: 44px;
    left: 44px
}

.inset-y-12 {
    top: 48px;
    bottom: 48px
}

.inset-x-12 {
    right: 48px;
    left: 48px
}

.inset-y-14 {
    top: 56px;
    bottom: 56px
}

.inset-x-14 {
    right: 56px;
    left: 56px
}

.inset-y-16 {
    top: 64px;
    bottom: 64px
}

.inset-x-16 {
    right: 64px;
    left: 64px
}

.inset-y-20 {
    top: 80px;
    bottom: 80px
}

.inset-x-20 {
    right: 80px;
    left: 80px
}

.inset-y-24 {
    top: 96px;
    bottom: 96px
}

.inset-x-24 {
    right: 96px;
    left: 96px
}

.inset-y-28 {
    top: 112px;
    bottom: 112px
}

.inset-x-28 {
    right: 112px;
    left: 112px
}

.inset-y-32 {
    top: 128px;
    bottom: 128px
}

.inset-x-32 {
    right: 128px;
    left: 128px
}

.inset-y-36 {
    top: 144px;
    bottom: 144px
}

.inset-x-36 {
    right: 144px;
    left: 144px
}

.inset-y-40 {
    top: 160px;
    bottom: 160px
}

.inset-x-40 {
    right: 160px;
    left: 160px
}

.inset-y-44 {
    top: 176px;
    bottom: 176px
}

.inset-x-44 {
    right: 176px;
    left: 176px
}

.inset-y-48 {
    top: 192px;
    bottom: 192px
}

.inset-x-48 {
    right: 192px;
    left: 192px
}

.inset-y-52 {
    top: 208px;
    bottom: 208px
}

.inset-x-52 {
    right: 208px;
    left: 208px
}

.inset-y-56 {
    top: 224px;
    bottom: 224px
}

.inset-x-56 {
    right: 224px;
    left: 224px
}

.inset-y-60 {
    top: 240px;
    bottom: 240px
}

.inset-x-60 {
    right: 240px;
    left: 240px
}

.inset-y-64 {
    top: 256px;
    bottom: 256px
}

.inset-x-64 {
    right: 256px;
    left: 256px
}

.inset-y-72 {
    top: 288px;
    bottom: 288px
}

.inset-x-72 {
    right: 288px;
    left: 288px
}

.inset-y-80 {
    top: 320px;
    bottom: 320px
}

.inset-x-80 {
    right: 320px;
    left: 320px
}

.inset-y-96 {
    top: 384px;
    bottom: 384px
}

.inset-x-96 {
    right: 384px;
    left: 384px
}

.inset-y-auto {
    top: auto;
    bottom: auto
}

.inset-x-auto {
    right: auto;
    left: auto
}

.inset-y-px {
    top: 1px;
    bottom: 1px
}

.inset-x-px {
    right: 1px;
    left: 1px
}

.inset-y-0d5 {
    top: 2px;
    bottom: 2px
}

.inset-x-0d5 {
    right: 2px;
    left: 2px
}

.inset-y-1d5 {
    top: 6px;
    bottom: 6px
}

.inset-x-1d5 {
    right: 6px;
    left: 6px
}

.inset-y-2d5 {
    top: 10px;
    bottom: 10px
}

.inset-x-2d5 {
    right: 10px;
    left: 10px
}

.inset-y-3d5 {
    top: 14px;
    bottom: 14px
}

.inset-x-3d5 {
    right: 14px;
    left: 14px
}

.-inset-y-0 {
    top: 0px;
    bottom: 0px
}

.-inset-x-0 {
    right: 0px;
    left: 0px
}

.-inset-y-1 {
    top: -4px;
    bottom: -4px
}

.-inset-x-1 {
    right: -4px;
    left: -4px
}

.-inset-y-2 {
    top: -8px;
    bottom: -8px
}

.-inset-x-2 {
    right: -8px;
    left: -8px
}

.-inset-y-3 {
    top: -12px;
    bottom: -12px
}

.-inset-x-3 {
    right: -12px;
    left: -12px
}

.-inset-y-4 {
    top: -16px;
    bottom: -16px
}

.-inset-x-4 {
    right: -16px;
    left: -16px
}

.-inset-y-5 {
    top: -20px;
    bottom: -20px
}

.-inset-x-5 {
    right: -20px;
    left: -20px
}

.-inset-y-6 {
    top: -24px;
    bottom: -24px
}

.-inset-x-6 {
    right: -24px;
    left: -24px
}

.-inset-y-7 {
    top: -28px;
    bottom: -28px
}

.-inset-x-7 {
    right: -28px;
    left: -28px
}

.-inset-y-8 {
    top: -32px;
    bottom: -32px
}

.-inset-x-8 {
    right: -32px;
    left: -32px
}

.-inset-y-9 {
    top: -36px;
    bottom: -36px
}

.-inset-x-9 {
    right: -36px;
    left: -36px
}

.-inset-y-10 {
    top: -40px;
    bottom: -40px
}

.-inset-x-10 {
    right: -40px;
    left: -40px
}

.-inset-y-11 {
    top: -44px;
    bottom: -44px
}

.-inset-x-11 {
    right: -44px;
    left: -44px
}

.-inset-y-12 {
    top: -48px;
    bottom: -48px
}

.-inset-x-12 {
    right: -48px;
    left: -48px
}

.-inset-y-14 {
    top: -56px;
    bottom: -56px
}

.-inset-x-14 {
    right: -56px;
    left: -56px
}

.-inset-y-16 {
    top: -64px;
    bottom: -64px
}

.-inset-x-16 {
    right: -64px;
    left: -64px
}

.-inset-y-20 {
    top: -80px;
    bottom: -80px
}

.-inset-x-20 {
    right: -80px;
    left: -80px
}

.-inset-y-24 {
    top: -96px;
    bottom: -96px
}

.-inset-x-24 {
    right: -96px;
    left: -96px
}

.-inset-y-28 {
    top: -112px;
    bottom: -112px
}

.-inset-x-28 {
    right: -112px;
    left: -112px
}

.-inset-y-32 {
    top: -128px;
    bottom: -128px
}

.-inset-x-32 {
    right: -128px;
    left: -128px
}

.-inset-y-36 {
    top: -144px;
    bottom: -144px
}

.-inset-x-36 {
    right: -144px;
    left: -144px
}

.-inset-y-40 {
    top: -160px;
    bottom: -160px
}

.-inset-x-40 {
    right: -160px;
    left: -160px
}

.-inset-y-44 {
    top: -176px;
    bottom: -176px
}

.-inset-x-44 {
    right: -176px;
    left: -176px
}

.-inset-y-48 {
    top: -192px;
    bottom: -192px
}

.-inset-x-48 {
    right: -192px;
    left: -192px
}

.-inset-y-52 {
    top: -208px;
    bottom: -208px
}

.-inset-x-52 {
    right: -208px;
    left: -208px
}

.-inset-y-56 {
    top: -224px;
    bottom: -224px
}

.-inset-x-56 {
    right: -224px;
    left: -224px
}

.-inset-y-60 {
    top: -240px;
    bottom: -240px
}

.-inset-x-60 {
    right: -240px;
    left: -240px
}

.-inset-y-64 {
    top: -256px;
    bottom: -256px
}

.-inset-x-64 {
    right: -256px;
    left: -256px
}

.-inset-y-72 {
    top: -288px;
    bottom: -288px
}

.-inset-x-72 {
    right: -288px;
    left: -288px
}

.-inset-y-80 {
    top: -320px;
    bottom: -320px
}

.-inset-x-80 {
    right: -320px;
    left: -320px
}

.-inset-y-96 {
    top: -384px;
    bottom: -384px
}

.-inset-x-96 {
    right: -384px;
    left: -384px
}

.-inset-y-px {
    top: -1px;
    bottom: -1px
}

.-inset-x-px {
    right: -1px;
    left: -1px
}

.-inset-y-0d5 {
    top: -2px;
    bottom: -2px
}

.-inset-x-0d5 {
    right: -2px;
    left: -2px
}

.-inset-y-1d5 {
    top: -6px;
    bottom: -6px
}

.-inset-x-1d5 {
    right: -6px;
    left: -6px
}

.-inset-y-2d5 {
    top: -10px;
    bottom: -10px
}

.-inset-x-2d5 {
    right: -10px;
    left: -10px
}

.-inset-y-3d5 {
    top: -14px;
    bottom: -14px
}

.-inset-x-3d5 {
    right: -14px;
    left: -14px
}

.inset-y-1_2 {
    top: 50%;
    bottom: 50%
}

.inset-x-1_2 {
    right: 50%;
    left: 50%
}

.inset-y-1_3 {
    top: 33.333333%;
    bottom: 33.333333%
}

.inset-x-1_3 {
    right: 33.333333%;
    left: 33.333333%
}

.inset-y-2_3 {
    top: 66.666667%;
    bottom: 66.666667%
}

.inset-x-2_3 {
    right: 66.666667%;
    left: 66.666667%
}

.inset-y-1_4 {
    top: 25%;
    bottom: 25%
}

.inset-x-1_4 {
    right: 25%;
    left: 25%
}

.inset-y-2_4 {
    top: 50%;
    bottom: 50%
}

.inset-x-2_4 {
    right: 50%;
    left: 50%
}

.inset-y-3_4 {
    top: 75%;
    bottom: 75%
}

.inset-x-3_4 {
    right: 75%;
    left: 75%
}

.inset-y-full {
    top: 100%;
    bottom: 100%
}

.inset-x-full {
    right: 100%;
    left: 100%
}

.-inset-y-1_2 {
    top: -50%;
    bottom: -50%
}

.-inset-x-1_2 {
    right: -50%;
    left: -50%
}

.-inset-y-1_3 {
    top: -33.333333%;
    bottom: -33.333333%
}

.-inset-x-1_3 {
    right: -33.333333%;
    left: -33.333333%
}

.-inset-y-2_3 {
    top: -66.666667%;
    bottom: -66.666667%
}

.-inset-x-2_3 {
    right: -66.666667%;
    left: -66.666667%
}

.-inset-y-1_4 {
    top: -25%;
    bottom: -25%
}

.-inset-x-1_4 {
    right: -25%;
    left: -25%
}

.-inset-y-2_4 {
    top: -50%;
    bottom: -50%
}

.-inset-x-2_4 {
    right: -50%;
    left: -50%
}

.-inset-y-3_4 {
    top: -75%;
    bottom: -75%
}

.-inset-x-3_4 {
    right: -75%;
    left: -75%
}

.-inset-y-full {
    top: -100%;
    bottom: -100%
}

.-inset-x-full {
    right: -100%;
    left: -100%
}

.top-0 {
    top: 0px
}

.right-0 {
    right: 0px
}

.bottom-0 {
    bottom: 0px
}

.left-0 {
    left: 0px
}

.top-1 {
    top: 4px
}

.right-1 {
    right: 4px
}

.bottom-1 {
    bottom: 4px
}

.left-1 {
    left: 4px
}

.top-2 {
    top: 8px
}

.right-2 {
    right: 8px
}

.bottom-2 {
    bottom: 8px
}

.left-2 {
    left: 8px
}

.top-3 {
    top: 12px
}

.right-3 {
    right: 12px
}

.bottom-3 {
    bottom: 12px
}

.left-3 {
    left: 12px
}

.top-4 {
    top: 16px
}

.right-4 {
    right: 16px
}

.bottom-4 {
    bottom: 16px
}

.left-4 {
    left: 16px
}

.top-5 {
    top: 20px
}

.right-5 {
    right: 20px
}

.bottom-5 {
    bottom: 20px
}

.left-5 {
    left: 20px
}

.top-6 {
    top: 24px
}

.right-6 {
    right: 24px
}

.bottom-6 {
    bottom: 24px
}

.left-6 {
    left: 24px
}

.top-7 {
    top: 28px
}

.right-7 {
    right: 28px
}

.bottom-7 {
    bottom: 28px
}

.left-7 {
    left: 28px
}

.top-8 {
    top: 32px
}

.right-8 {
    right: 32px
}

.bottom-8 {
    bottom: 32px
}

.left-8 {
    left: 32px
}

.top-9 {
    top: 36px
}

.right-9 {
    right: 36px
}

.bottom-9 {
    bottom: 36px
}

.left-9 {
    left: 36px
}

.top-10 {
    top: 40px
}

.right-10 {
    right: 40px
}

.bottom-10 {
    bottom: 40px
}

.left-10 {
    left: 40px
}

.top-11 {
    top: 44px
}

.right-11 {
    right: 44px
}

.bottom-11 {
    bottom: 44px
}

.left-11 {
    left: 44px
}

.top-12 {
    top: 48px
}

.right-12 {
    right: 48px
}

.bottom-12 {
    bottom: 48px
}

.left-12 {
    left: 48px
}

.top-14 {
    top: 56px
}

.right-14 {
    right: 56px
}

.bottom-14 {
    bottom: 56px
}

.left-14 {
    left: 56px
}

.top-16 {
    top: 64px
}

.right-16 {
    right: 64px
}

.bottom-16 {
    bottom: 64px
}

.left-16 {
    left: 64px
}

.top-20 {
    top: 80px
}

.right-20 {
    right: 80px
}

.bottom-20 {
    bottom: 80px
}

.left-20 {
    left: 80px
}

.top-24 {
    top: 96px
}

.right-24 {
    right: 96px
}

.bottom-24 {
    bottom: 96px
}

.left-24 {
    left: 96px
}

.top-28 {
    top: 112px
}

.right-28 {
    right: 112px
}

.bottom-28 {
    bottom: 112px
}

.left-28 {
    left: 112px
}

.top-32 {
    top: 128px
}

.right-32 {
    right: 128px
}

.bottom-32 {
    bottom: 128px
}

.left-32 {
    left: 128px
}

.top-36 {
    top: 144px
}

.right-36 {
    right: 144px
}

.bottom-36 {
    bottom: 144px
}

.left-36 {
    left: 144px
}

.top-40 {
    top: 160px
}

.right-40 {
    right: 160px
}

.bottom-40 {
    bottom: 160px
}

.left-40 {
    left: 160px
}

.top-44 {
    top: 176px
}

.right-44 {
    right: 176px
}

.bottom-44 {
    bottom: 176px
}

.left-44 {
    left: 176px
}

.top-48 {
    top: 192px
}

.right-48 {
    right: 192px
}

.bottom-48 {
    bottom: 192px
}

.left-48 {
    left: 192px
}

.top-52 {
    top: 208px
}

.right-52 {
    right: 208px
}

.bottom-52 {
    bottom: 208px
}

.left-52 {
    left: 208px
}

.top-56 {
    top: 224px
}

.right-56 {
    right: 224px
}

.bottom-56 {
    bottom: 224px
}

.left-56 {
    left: 224px
}

.top-60 {
    top: 240px
}

.right-60 {
    right: 240px
}

.bottom-60 {
    bottom: 240px
}

.left-60 {
    left: 240px
}

.top-64 {
    top: 256px
}

.right-64 {
    right: 256px
}

.bottom-64 {
    bottom: 256px
}

.left-64 {
    left: 256px
}

.top-72 {
    top: 288px
}

.right-72 {
    right: 288px
}

.bottom-72 {
    bottom: 288px
}

.left-72 {
    left: 288px
}

.top-80 {
    top: 320px
}

.right-80 {
    right: 320px
}

.bottom-80 {
    bottom: 320px
}

.left-80 {
    left: 320px
}

.top-96 {
    top: 384px
}

.right-96 {
    right: 384px
}

.bottom-96 {
    bottom: 384px
}

.left-96 {
    left: 384px
}

.top-auto {
    top: auto
}

.right-auto {
    right: auto
}

.bottom-auto {
    bottom: auto
}

.left-auto {
    left: auto
}

.top-px {
    top: 1px
}

.right-px {
    right: 1px
}

.bottom-px {
    bottom: 1px
}

.left-px {
    left: 1px
}

.top-0d5 {
    top: 2px
}

.right-0d5 {
    right: 2px
}

.bottom-0d5 {
    bottom: 2px
}

.left-0d5 {
    left: 2px
}

.top-1d5 {
    top: 6px
}

.right-1d5 {
    right: 6px
}

.bottom-1d5 {
    bottom: 6px
}

.left-1d5 {
    left: 6px
}

.top-2d5 {
    top: 10px
}

.right-2d5 {
    right: 10px
}

.bottom-2d5 {
    bottom: 10px
}

.left-2d5 {
    left: 10px
}

.top-3d5 {
    top: 14px
}

.right-3d5 {
    right: 14px
}

.bottom-3d5 {
    bottom: 14px
}

.left-3d5 {
    left: 14px
}

.-top-0 {
    top: 0px
}

.-right-0 {
    right: 0px
}

.-bottom-0 {
    bottom: 0px
}

.-left-0 {
    left: 0px
}

.-top-1 {
    top: -4px
}

.-right-1 {
    right: -4px
}

.-bottom-1 {
    bottom: -4px
}

.-left-1 {
    left: -4px
}

.-top-2 {
    top: -8px
}

.-right-2 {
    right: -8px
}

.-bottom-2 {
    bottom: -8px
}

.-left-2 {
    left: -8px
}

.-top-3 {
    top: -12px
}

.-right-3 {
    right: -12px
}

.-bottom-3 {
    bottom: -12px
}

.-left-3 {
    left: -12px
}

.-top-4 {
    top: -16px
}

.-right-4 {
    right: -16px
}

.-bottom-4 {
    bottom: -16px
}

.-left-4 {
    left: -16px
}

.-top-5 {
    top: -20px
}

.-right-5 {
    right: -20px
}

.-bottom-5 {
    bottom: -20px
}

.-left-5 {
    left: -20px
}

.-top-6 {
    top: -24px
}

.-right-6 {
    right: -24px
}

.-bottom-6 {
    bottom: -24px
}

.-left-6 {
    left: -24px
}

.-top-7 {
    top: -28px
}

.-right-7 {
    right: -28px
}

.-bottom-7 {
    bottom: -28px
}

.-left-7 {
    left: -28px
}

.-top-8 {
    top: -32px
}

.-right-8 {
    right: -32px
}

.-bottom-8 {
    bottom: -32px
}

.-left-8 {
    left: -32px
}

.-top-9 {
    top: -36px
}

.-right-9 {
    right: -36px
}

.-bottom-9 {
    bottom: -36px
}

.-left-9 {
    left: -36px
}

.-top-10 {
    top: -40px
}

.-right-10 {
    right: -40px
}

.-bottom-10 {
    bottom: -40px
}

.-left-10 {
    left: -40px
}

.-top-11 {
    top: -44px
}

.-right-11 {
    right: -44px
}

.-bottom-11 {
    bottom: -44px
}

.-left-11 {
    left: -44px
}

.-top-12 {
    top: -48px
}

.-right-12 {
    right: -48px
}

.-bottom-12 {
    bottom: -48px
}

.-left-12 {
    left: -48px
}

.-top-14 {
    top: -56px
}

.-right-14 {
    right: -56px
}

.-bottom-14 {
    bottom: -56px
}

.-left-14 {
    left: -56px
}

.-top-16 {
    top: -64px
}

.-right-16 {
    right: -64px
}

.-bottom-16 {
    bottom: -64px
}

.-left-16 {
    left: -64px
}

.-top-20 {
    top: -80px
}

.-right-20 {
    right: -80px
}

.-bottom-20 {
    bottom: -80px
}

.-left-20 {
    left: -80px
}

.-top-24 {
    top: -96px
}

.-right-24 {
    right: -96px
}

.-bottom-24 {
    bottom: -96px
}

.-left-24 {
    left: -96px
}

.-top-28 {
    top: -112px
}

.-right-28 {
    right: -112px
}

.-bottom-28 {
    bottom: -112px
}

.-left-28 {
    left: -112px
}

.-top-32 {
    top: -128px
}

.-right-32 {
    right: -128px
}

.-bottom-32 {
    bottom: -128px
}

.-left-32 {
    left: -128px
}

.-top-36 {
    top: -144px
}

.-right-36 {
    right: -144px
}

.-bottom-36 {
    bottom: -144px
}

.-left-36 {
    left: -144px
}

.-top-40 {
    top: -160px
}

.-right-40 {
    right: -160px
}

.-bottom-40 {
    bottom: -160px
}

.-left-40 {
    left: -160px
}

.-top-44 {
    top: -176px
}

.-right-44 {
    right: -176px
}

.-bottom-44 {
    bottom: -176px
}

.-left-44 {
    left: -176px
}

.-top-48 {
    top: -192px
}

.-right-48 {
    right: -192px
}

.-bottom-48 {
    bottom: -192px
}

.-left-48 {
    left: -192px
}

.-top-52 {
    top: -208px
}

.-right-52 {
    right: -208px
}

.-bottom-52 {
    bottom: -208px
}

.-left-52 {
    left: -208px
}

.-top-56 {
    top: -224px
}

.-right-56 {
    right: -224px
}

.-bottom-56 {
    bottom: -224px
}

.-left-56 {
    left: -224px
}

.-top-60 {
    top: -240px
}

.-right-60 {
    right: -240px
}

.-bottom-60 {
    bottom: -240px
}

.-left-60 {
    left: -240px
}

.-top-64 {
    top: -256px
}

.-right-64 {
    right: -256px
}

.-bottom-64 {
    bottom: -256px
}

.-left-64 {
    left: -256px
}

.-top-72 {
    top: -288px
}

.-right-72 {
    right: -288px
}

.-bottom-72 {
    bottom: -288px
}

.-left-72 {
    left: -288px
}

.-top-80 {
    top: -320px
}

.-right-80 {
    right: -320px
}

.-bottom-80 {
    bottom: -320px
}

.-left-80 {
    left: -320px
}

.-top-96 {
    top: -384px
}

.-right-96 {
    right: -384px
}

.-bottom-96 {
    bottom: -384px
}

.-left-96 {
    left: -384px
}

.-top-px {
    top: -1px
}

.-right-px {
    right: -1px
}

.-bottom-px {
    bottom: -1px
}

.-left-px {
    left: -1px
}

.-top-0d5 {
    top: -2px
}

.-right-0d5 {
    right: -2px
}

.-bottom-0d5 {
    bottom: -2px
}

.-left-0d5 {
    left: -2px
}

.-top-1d5 {
    top: -6px
}

.-right-1d5 {
    right: -6px
}

.-bottom-1d5 {
    bottom: -6px
}

.-left-1d5 {
    left: -6px
}

.-top-2d5 {
    top: -10px
}

.-right-2d5 {
    right: -10px
}

.-bottom-2d5 {
    bottom: -10px
}

.-left-2d5 {
    left: -10px
}

.-top-3d5 {
    top: -14px
}

.-right-3d5 {
    right: -14px
}

.-bottom-3d5 {
    bottom: -14px
}

.-left-3d5 {
    left: -14px
}

.top-1_2 {
    top: 50%
}

.right-1_2 {
    right: 50%
}

.bottom-1_2 {
    bottom: 50%
}

.left-1_2 {
    left: 50%
}

.top-1_3 {
    top: 33.333333%
}

.right-1_3 {
    right: 33.333333%
}

.bottom-1_3 {
    bottom: 33.333333%
}

.left-1_3 {
    left: 33.333333%
}

.top-2_3 {
    top: 66.666667%
}

.right-2_3 {
    right: 66.666667%
}

.bottom-2_3 {
    bottom: 66.666667%
}

.left-2_3 {
    left: 66.666667%
}

.top-1_4 {
    top: 25%
}

.right-1_4 {
    right: 25%
}

.bottom-1_4 {
    bottom: 25%
}

.left-1_4 {
    left: 25%
}

.top-2_4 {
    top: 50%
}

.right-2_4 {
    right: 50%
}

.bottom-2_4 {
    bottom: 50%
}

.left-2_4 {
    left: 50%
}

.top-3_4 {
    top: 75%
}

.right-3_4 {
    right: 75%
}

.bottom-3_4 {
    bottom: 75%
}

.left-3_4 {
    left: 75%
}

.top-full {
    top: 100%
}

.right-full {
    right: 100%
}

.bottom-full {
    bottom: 100%
}

.left-full {
    left: 100%
}

.-top-1_2 {
    top: -50%
}

.-right-1_2 {
    right: -50%
}

.-bottom-1_2 {
    bottom: -50%
}

.-left-1_2 {
    left: -50%
}

.-top-1_3 {
    top: -33.333333%
}

.-right-1_3 {
    right: -33.333333%
}

.-bottom-1_3 {
    bottom: -33.333333%
}

.-left-1_3 {
    left: -33.333333%
}

.-top-2_3 {
    top: -66.666667%
}

.-right-2_3 {
    right: -66.666667%
}

.-bottom-2_3 {
    bottom: -66.666667%
}

.-left-2_3 {
    left: -66.666667%
}

.-top-1_4 {
    top: -25%
}

.-right-1_4 {
    right: -25%
}

.-bottom-1_4 {
    bottom: -25%
}

.-left-1_4 {
    left: -25%
}

.-top-2_4 {
    top: -50%
}

.-right-2_4 {
    right: -50%
}

.-bottom-2_4 {
    bottom: -50%
}

.-left-2_4 {
    left: -50%
}

.-top-3_4 {
    top: -75%
}

.-right-3_4 {
    right: -75%
}

.-bottom-3_4 {
    bottom: -75%
}

.-left-3_4 {
    left: -75%
}

.-top-full {
    top: -100%
}

.-right-full {
    right: -100%
}

.-bottom-full {
    bottom: -100%
}

.-left-full {
    left: -100%
}

.resize-none {
    resize: none
}

.resize-y {
    resize: vertical
}

.resize-x {
    resize: horizontal
}

.resize {
    resize: both
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-inset {
    --tw-ring-inset: inset
}

.ring-offset-transparent {
    --tw-ring-offset-color: transparent
}

.ring-offset-current {
    --tw-ring-offset-color: currentColor
}

.ring-offset-black {
    --tw-ring-offset-color: #000
}

.ring-offset-white {
    --tw-ring-offset-color: #fff
}

.ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb
}

.ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6
}

.ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb
}

.ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db
}

.ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af
}

.ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280
}

.ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563
}

.ring-offset-gray-700 {
    --tw-ring-offset-color: #374151
}

.ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937
}

.ring-offset-gray-900 {
    --tw-ring-offset-color: #111827
}

.ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2
}

.ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2
}

.ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca
}

.ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5
}

.ring-offset-red-400 {
    --tw-ring-offset-color: #f87171
}

.ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444
}

.ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626
}

.ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c
}

.ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b
}

.ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d
}

.ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb
}

.ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7
}

.ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a
}

.ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d
}

.ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24
}

.ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b
}

.ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706
}

.ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309
}

.ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e
}

.ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f
}

.ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5
}

.ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5
}

.ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0
}

.ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7
}

.ring-offset-green-400 {
    --tw-ring-offset-color: #34d399
}

.ring-offset-green-500 {
    --tw-ring-offset-color: #10b981
}

.ring-offset-green-600 {
    --tw-ring-offset-color: #059669
}

.ring-offset-green-700 {
    --tw-ring-offset-color: #047857
}

.ring-offset-green-800 {
    --tw-ring-offset-color: #065f46
}

.ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b
}

.ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff
}

.ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe
}

.ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe
}

.ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd
}

.ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa
}

.ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6
}

.ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb
}

.ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8
}

.ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af
}

.ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a
}

.ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff
}

.ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff
}

.ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe
}

.ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc
}

.ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8
}

.ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1
}

.ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5
}

.ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca
}

.ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3
}

.ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81
}

.ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff
}

.ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe
}

.ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe
}

.ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd
}

.ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa
}

.ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6
}

.ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed
}

.ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9
}

.ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6
}

.ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95
}

.ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8
}

.ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3
}

.ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8
}

.ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4
}

.ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6
}

.ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899
}

.ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777
}

.ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d
}

.ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d
}

.ring-offset-pink-900 {
    --tw-ring-offset-color: #831843
}

.ring-offset-0 {
    --tw-ring-offset-width: 0px
}

.ring-offset-1 {
    --tw-ring-offset-width: 1px
}

.ring-offset-2 {
    --tw-ring-offset-width: 2px
}

.ring-offset-4 {
    --tw-ring-offset-width: 4px
}

.ring-offset-8 {
    --tw-ring-offset-width: 8px
}

.ring-transparent {
    --tw-ring-color: transparent
}

.ring-current {
    --tw-ring-color: currentColor
}

.ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
}

.ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
}

.ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
}

.ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
}

.ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
}

.ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
}

.ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
}

.ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
}

.ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
}

.ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

.ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
}

.ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
}

.ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
}

.ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
}

.ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
}

.ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
}

.ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
}

.ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
}

.ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
}

.ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
}

.ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
}

.ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
}

.ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
}

.ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
}

.ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
}

.ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
}

.ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
}

.ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
}

.ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
}

.ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
}

.ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
}

.ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
}

.ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
}

.ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
}

.ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
}

.ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
}

.ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
}

.ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
}

.ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
}

.ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
}

.ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
}

.ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
}

.ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
}

.ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
}

.ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
}

.ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
}

.ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
}

.ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
}

.ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
}

.ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
}

.ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
}

.ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
}

.ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
}

.ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
}

.ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
}

.ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
}

.ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

.ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

.ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
}

.ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
}

.ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
}

.ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
}

.ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
}

.ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
}

.ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
}

.ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
}

.ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
}

.ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
}

.ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
}

.ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
}

.ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
}

.ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
}

.ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
}

.ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
}

.ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
}

.ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
}

.ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
}

.ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
}

.ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
}

.ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
}

.ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
}

.ring-opacity-0 {
    --tw-ring-opacity: 0
}

.ring-opacity-5 {
    --tw-ring-opacity: 0.05
}

.ring-opacity-10 {
    --tw-ring-opacity: 0.1
}

.ring-opacity-20 {
    --tw-ring-opacity: 0.2
}

.ring-opacity-25 {
    --tw-ring-opacity: 0.25
}

.ring-opacity-30 {
    --tw-ring-opacity: 0.3
}

.ring-opacity-40 {
    --tw-ring-opacity: 0.4
}

.ring-opacity-50 {
    --tw-ring-opacity: 0.5
}

.ring-opacity-60 {
    --tw-ring-opacity: 0.6
}

.ring-opacity-70 {
    --tw-ring-opacity: 0.7
}

.ring-opacity-75 {
    --tw-ring-opacity: 0.75
}

.ring-opacity-80 {
    --tw-ring-opacity: 0.8
}

.ring-opacity-90 {
    --tw-ring-opacity: 0.9
}

.ring-opacity-95 {
    --tw-ring-opacity: 0.95
}

.ring-opacity-100 {
    --tw-ring-opacity: 1
}

.fill-current {
    fill: currentColor
}

.stroke-current {
    stroke: currentColor
}

.stroke-0 {
    stroke-width: 0
}

.stroke-1 {
    stroke-width: 1
}

.stroke-2 {
    stroke-width: 2
}

.table-auto {
    table-layout: auto
}

.table-fixed {
    table-layout: fixed
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-justify {
    text-align: justify
}

.text-transparent {
    color: transparent
}

.text-current {
    color: currentColor
}

.text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
}

.text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
}

.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
}

.text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
}

.text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
}

.text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
}

.text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
}

.text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(245,85,69, var(--tw-text-opacity))
}

.text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
}

.text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
}

.text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
}

.text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
}

.text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
}

.text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
}

.text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
}

.text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
}

.text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
}

.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
}

.text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
}

.text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
}

.text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
}

.text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
}

.text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
}

.text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
}

.text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
}

.text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
}

.text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
}

.text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
}

.text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
}

.text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
}

.text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
}

.text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
}

.text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
}

.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
}

.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(50,150,250, var(--tw-text-opacity))
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
}

.text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
}

.text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(41, 71, 102, var(--tw-text-opacity))
}

.text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
}

.text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
}

.text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
}

.text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
}

.text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
}

.text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
}

.text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
}

.text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
}

.text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
}

.text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
}

.text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
}

.text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
}

.text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
}

.text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
}

.text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
}

.text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
}

.text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
}

.text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
}

.text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
}

.text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
}

.text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
}

.text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
}

.text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
}

.text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
}

.text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
}

.text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
}

.text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
}

.text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
}

.text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
}

.text-opacity-0 {
    --tw-text-opacity: 0
}

.text-opacity-5 {
    --tw-text-opacity: 0.05
}

.text-opacity-10 {
    --tw-text-opacity: 0.1
}

.text-opacity-20 {
    --tw-text-opacity: 0.2
}

.text-opacity-25 {
    --tw-text-opacity: 0.25
}

.text-opacity-30 {
    --tw-text-opacity: 0.3
}

.text-opacity-40 {
    --tw-text-opacity: 0.4
}

.text-opacity-50 {
    --tw-text-opacity: 0.5
}

.text-opacity-60 {
    --tw-text-opacity: 0.6
}

.text-opacity-70 {
    --tw-text-opacity: 0.7
}

.text-opacity-75 {
    --tw-text-opacity: 0.75
}

.text-opacity-80 {
    --tw-text-opacity: 0.8
}

.text-opacity-90 {
    --tw-text-opacity: 0.9
}

.text-opacity-95 {
    --tw-text-opacity: 0.95
}

.text-opacity-100 {
    --tw-text-opacity: 1
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.overflow-ellipsis {
    text-overflow: ellipsis
}

.overflow-clip {
    text-overflow: clip
}

.italic {
    font-style: italic
}

.not-italic {
    font-style: normal
}

.uppercase {
    text-transform: uppercase
}

.lowercase {
    text-transform: lowercase
}

.capitalize {
    text-transform: capitalize
}

.normal-case {
    text-transform: none
}

.underline {
    text-decoration: underline
}

.line-through {
    text-decoration: line-through
}

.no-underline {
    text-decoration: none
}

.antialiased {}

.subpixel-antialiased {}

.ordinal,
.slashed-zero,
.lining-nums,
.oldstyle-nums,
.proportional-nums,
.tabular-nums,
.diagonal-fractions,
.stacked-fractions {
    --tw-ordinal: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-slashed-zero: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-numeric-figure: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-numeric-spacing: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-numeric-fraction: var(--tw-empty, /*!*/
    /*!*/
    );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.normal-nums {
    font-variant-numeric: normal
}

.ordinal {
    --tw-ordinal: ordinal
}

.slashed-zero {
    --tw-slashed-zero: slashed-zero
}

.lining-nums {
    --tw-numeric-figure: lining-nums
}

.oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums
}

.proportional-nums {
    --tw-numeric-spacing: proportional-nums
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums
}

.diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions
}

.stacked-fractions {
    --tw-numeric-fraction: stacked-fractions
}

.tracking-tighter {
    letter-spacing: -0.05em
}

.tracking-tight {
    letter-spacing: -0.025em
}

.tracking-normal {
    letter-spacing: 0em
}

.tracking-wide {
    letter-spacing: 0.025em
}

.tracking-wider {
    letter-spacing: 0.05em
}

.tracking-widest {
    letter-spacing: 0.1em
}

.select-none {
    user-select: none
}

.select-text {
    user-select: text
}

.select-all {
    user-select: all
}

.select-auto {
    user-select: auto
}

.align-baseline {
    vertical-align: baseline
}

.align-top {
    vertical-align: top
}

.align-middle {
    vertical-align: center
}

.align-bottom {
    vertical-align: bottom
}

.align-text-top {
    vertical-align: text-top
}

.align-text-bottom {
    vertical-align: text-bottom
}

.visible {
    visibility: visible
}

.invisible {
    visibility: collapse
}

.whitespace-normal {
    white-space: normal
}

.whitespace-nowrap {
    white-space: nowrap
}

.whitespace-pre {
    white-space: pre
}

.whitespace-pre-line {
    white-space: pre-line
}

.whitespace-pre-wrap {
    white-space: pre-wrap
}

.break-normal {
    overflow-wrap: normal;
    word-break: normal
}

.break-words {
    overflow-wrap: break-word
}

.break-all {
    word-break: break-all
}

.w-0 {
    width: 0px
}

.w-1 {
    width: 4px
}

.w-2 {
    width: 8px
}

.w-3 {
    width: 12px
}

.w-4 {
    width: 16px
}

.w-5 {
    width: 20px
}

.w-6 {
    width: 24px
}

.w-7 {
    width: 28px
}

.w-8 {
    width: 32px
}

.w-9 {
    width: 36px
}

.w-10 {
    width: 40px
}

.w-11 {
    width: 44px
}

.w-12 {
    width: 48px
}

.w-14 {
    width: 56px
}

.w-16 {
    width: 64px
}

.w-20 {
    width: 80px
}

.w-24 {
    width: 96px
}

.w-28 {
    width: 112px
}

.w-32 {
    width: 128px
}

.w-36 {
    width: 144px
}

.w-40 {
    width: 160px
}

.w-44 {
    width: 176px
}

.w-48 {
    width: 192px
}

.w-52 {
    width: 208px
}

.w-56 {
    width: 224px
}

.w-60 {
    width: 240px
}

.w-64 {
    width: 256px
}

.w-72 {
    width: 288px
}

.w-80 {
    width: 320px
}

.w-96 {
    width: 384px
}

.w-auto {
    width: auto
}

.w-px {
    width: 1px
}

.w-0d5 {
    width: 2px
}

.w-1d5 {
    width: 6px
}

.w-2d5 {
    width: 10px
}

.w-3d5 {
    width: 14px
}

.w-1_2 {
    width: 50%
}

.w-1_3 {
    width: 33.333333%
}

.w-2_3 {
    width: 66.666667%
}

.w-1_4 {
    width: 25%
}

.w-2_4 {
    width: 50%
}

.w-3_4 {
    width: 75%
}

.w-1_5 {
    width: 20%
}

.w-2_5 {
    width: 40%
}

.w-3_5 {
    width: 60%
}

.w-4_5 {
    width: 80%
}

.w-1_6 {
    width: 16.666667%
}

.w-2_6 {
    width: 33.333333%
}

.w-3_6 {
    width: 50%
}

.w-4_6 {
    width: 66.666667%
}

.w-5_6 {
    width: 83.333333%
}

.w-1_12 {
    width: 8.333333%
}

.w-2_12 {
    width: 16.666667%
}

.w-3_12 {
    width: 25%
}

.w-4_12 {
    width: 33.333333%
}

.w-5_12 {
    width: 41.666667%
}

.w-6_12 {
    width: 50%
}

.w-7_12 {
    width: 58.333333%
}

.w-8_12 {
    width: 66.666667%
}

.w-9_12 {
    width: 75%
}

.w-10_12 {
    width: 83.333333%
}

.w-11_12 {
    width: 91.666667%
}

.w-full {
    width: 100%
}

.w-screen {
    width: 100vw
}

.w-min {
    width: min-content
}

.w-max {
    width: max-content
}

.z-0 {
    z-index: 0
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.z-30 {
    z-index: 30
}

.z-40 {
    z-index: 40
}

.z-50 {
    z-index: 50
}

.z-auto {
    z-index: auto
}

.gap-0 {
    gap: 0px
}

.gap-1 {
    gap: 4px
}

.gap-2 {
    gap: 8px
}

.gap-3 {
    gap: 12px
}

.gap-4 {
    gap: 16px
}

.gap-5 {
    gap: 20px
}

.gap-6 {
    gap: 24px
}

.gap-7 {
    gap: 28px
}

.gap-8 {
    gap: 32px
}

.gap-9 {
    gap: 36px
}

.gap-10 {
    gap: 40px
}

.gap-11 {
    gap: 44px
}

.gap-12 {
    gap: 48px
}

.gap-14 {
    gap: 56px
}

.gap-16 {
    gap: 64px
}

.gap-20 {
    gap: 80px
}

.gap-24 {
    gap: 96px
}

.gap-28 {
    gap: 112px
}

.gap-32 {
    gap: 128px
}

.gap-36 {
    gap: 144px
}

.gap-40 {
    gap: 160px
}

.gap-44 {
    gap: 176px
}

.gap-48 {
    gap: 192px
}

.gap-52 {
    gap: 208px
}

.gap-56 {
    gap: 224px
}

.gap-60 {
    gap: 240px
}

.gap-64 {
    gap: 256px
}

.gap-72 {
    gap: 288px
}

.gap-80 {
    gap: 320px
}

.gap-96 {
    gap: 384px
}

.gap-px {
    gap: 1px
}

.gap-0d5 {
    gap: 2px
}

.gap-1d5 {
    gap: 6px
}

.gap-2d5 {
    gap: 10px
}

.gap-3d5 {
    gap: 14px
}

.gap-x-0 {
    column-gap: 0px
}

.gap-x-1 {
    column-gap: 4px
}

.gap-x-2 {
    column-gap: 8px
}

.gap-x-3 {
    column-gap: 12px
}

.gap-x-4 {
    column-gap: 16px
}

.gap-x-5 {
    column-gap: 20px
}

.gap-x-6 {
    column-gap: 24px
}

.gap-x-7 {
    column-gap: 28px
}

.gap-x-8 {
    column-gap: 32px
}

.gap-x-9 {
    column-gap: 36px
}

.gap-x-10 {
    column-gap: 40px
}

.gap-x-11 {
    column-gap: 44px
}

.gap-x-12 {
    column-gap: 48px
}

.gap-x-14 {
    column-gap: 56px
}

.gap-x-16 {
    column-gap: 64px
}

.gap-x-20 {
    column-gap: 80px
}

.gap-x-24 {
    column-gap: 96px
}

.gap-x-28 {
    column-gap: 112px
}

.gap-x-32 {
    column-gap: 128px
}

.gap-x-36 {
    column-gap: 144px
}

.gap-x-40 {
    column-gap: 160px
}

.gap-x-44 {
    column-gap: 176px
}

.gap-x-48 {
    column-gap: 192px
}

.gap-x-52 {
    column-gap: 208px
}

.gap-x-56 {
    column-gap: 224px
}

.gap-x-60 {
    column-gap: 240px
}

.gap-x-64 {
    column-gap: 256px
}

.gap-x-72 {
    column-gap: 288px
}

.gap-x-80 {
    column-gap: 320px
}

.gap-x-96 {
    column-gap: 384px
}

.gap-x-px {
    column-gap: 1px
}

.gap-x-0d5 {
    column-gap: 2px
}

.gap-x-1d5 {
    column-gap: 6px
}

.gap-x-2d5 {
    column-gap: 10px
}

.gap-x-3d5 {
    column-gap: 14px
}

.gap-y-0 {
    row-gap: 0px
}

.gap-y-1 {
    row-gap: 4px
}

.gap-y-2 {
    row-gap: 8px
}

.gap-y-3 {
    row-gap: 12px
}

.gap-y-4 {
    row-gap: 16px
}

.gap-y-5 {
    row-gap: 20px
}

.gap-y-6 {
    row-gap: 24px
}

.gap-y-7 {
    row-gap: 28px
}

.gap-y-8 {
    row-gap: 32px
}

.gap-y-9 {
    row-gap: 36px
}

.gap-y-10 {
    row-gap: 40px
}

.gap-y-11 {
    row-gap: 44px
}

.gap-y-12 {
    row-gap: 48px
}

.gap-y-14 {
    row-gap: 56px
}

.gap-y-16 {
    row-gap: 64px
}

.gap-y-20 {
    row-gap: 80px
}

.gap-y-24 {
    row-gap: 96px
}

.gap-y-28 {
    row-gap: 112px
}

.gap-y-32 {
    row-gap: 128px
}

.gap-y-36 {
    row-gap: 144px
}

.gap-y-40 {
    row-gap: 160px
}

.gap-y-44 {
    row-gap: 176px
}

.gap-y-48 {
    row-gap: 192px
}

.gap-y-52 {
    row-gap: 208px
}

.gap-y-56 {
    row-gap: 224px
}

.gap-y-60 {
    row-gap: 240px
}

.gap-y-64 {
    row-gap: 256px
}

.gap-y-72 {
    row-gap: 288px
}

.gap-y-80 {
    row-gap: 320px
}

.gap-y-96 {
    row-gap: 384px
}

.gap-y-px {
    row-gap: 1px
}

.gap-y-0d5 {
    row-gap: 2px
}

.gap-y-1d5 {
    row-gap: 6px
}

.gap-y-2d5 {
    row-gap: 10px
}

.gap-y-3d5 {
    row-gap: 14px
}

.grid-flow-row {
    grid-auto-flow: row
}

.grid-flow-col {
    grid-auto-flow: column
}

.grid-flow-row-dense {
    grid-auto-flow: row dense
}

.grid-flow-col-dense {
    grid-auto-flow: column dense
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
}

.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-none {
    grid-template-columns: none
}

.auto-cols-auto {
    grid-auto-columns: auto
}

.auto-cols-min {
    grid-auto-columns: min-content
}

.auto-cols-max {
    grid-auto-columns: max-content
}

.auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
}

.col-auto {
    grid-column: auto
}

.col-span-1 {
    grid-column: span 1 / span 1
}

.col-span-2 {
    grid-column: span 2 / span 2
}

.col-span-3 {
    grid-column: span 3 / span 3
}

.col-span-4 {
    grid-column: span 4 / span 4
}

.col-span-5 {
    grid-column: span 5 / span 5
}

.col-span-6 {
    grid-column: span 6 / span 6
}

.col-span-7 {
    grid-column: span 7 / span 7
}

.col-span-8 {
    grid-column: span 8 / span 8
}

.col-span-9 {
    grid-column: span 9 / span 9
}

.col-span-10 {
    grid-column: span 10 / span 10
}

.col-span-11 {
    grid-column: span 11 / span 11
}

.col-span-12 {
    grid-column: span 12 / span 12
}

.col-span-full {
    grid-column: 1 / -1
}

.col-start-1 {
    grid-column-start: 1
}

.col-start-2 {
    grid-column-start: 2
}

.col-start-3 {
    grid-column-start: 3
}

.col-start-4 {
    grid-column-start: 4
}

.col-start-5 {
    grid-column-start: 5
}

.col-start-6 {
    grid-column-start: 6
}

.col-start-7 {
    grid-column-start: 7
}

.col-start-8 {
    grid-column-start: 8
}

.col-start-9 {
    grid-column-start: 9
}

.col-start-10 {
    grid-column-start: 10
}

.col-start-11 {
    grid-column-start: 11
}

.col-start-12 {
    grid-column-start: 12
}

.col-start-13 {
    grid-column-start: 13
}

.col-start-auto {
    grid-column-start: auto
}

.col-end-1 {
    grid-column-end: 1
}

.col-end-2 {
    grid-column-end: 2
}

.col-end-3 {
    grid-column-end: 3
}

.col-end-4 {
    grid-column-end: 4
}

.col-end-5 {
    grid-column-end: 5
}

.col-end-6 {
    grid-column-end: 6
}

.col-end-7 {
    grid-column-end: 7
}

.col-end-8 {
    grid-column-end: 8
}

.col-end-9 {
    grid-column-end: 9
}

.col-end-10 {
    grid-column-end: 10
}

.col-end-11 {
    grid-column-end: 11
}

.col-end-12 {
    grid-column-end: 12
}

.col-end-13 {
    grid-column-end: 13
}

.col-end-auto {
    grid-column-end: auto
}

.grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
}

.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
}

.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
}

.grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
}

.grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
}

.grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
}

.grid-rows-none {
    grid-template-rows: none
}

.auto-rows-auto {
    grid-auto-rows: auto
}

.auto-rows-min {
    grid-auto-rows: min-content
}

.auto-rows-max {
    grid-auto-rows: max-content
}

.auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
}

.row-auto {
    grid-row: auto
}

.row-span-1 {
    grid-row: span 1 / span 1
}

.row-span-2 {
    grid-row: span 2 / span 2
}

.row-span-3 {
    grid-row: span 3 / span 3
}

.row-span-4 {
    grid-row: span 4 / span 4
}

.row-span-5 {
    grid-row: span 5 / span 5
}

.row-span-6 {
    grid-row: span 6 / span 6
}

.row-span-full {
    grid-row: 1 / -1
}

.row-start-1 {
    grid-row-start: 1
}

.row-start-2 {
    grid-row-start: 2
}

.row-start-3 {
    grid-row-start: 3
}

.row-start-4 {
    grid-row-start: 4
}

.row-start-5 {
    grid-row-start: 5
}

.row-start-6 {
    grid-row-start: 6
}

.row-start-7 {
    grid-row-start: 7
}

.row-start-auto {
    grid-row-start: auto
}

.row-end-1 {
    grid-row-end: 1
}

.row-end-2 {
    grid-row-end: 2
}

.row-end-3 {
    grid-row-end: 3
}

.row-end-4 {
    grid-row-end: 4
}

.row-end-5 {
    grid-row-end: 5
}

.row-end-6 {
    grid-row-end: 6
}

.row-end-7 {
    grid-row-end: 7
}

.row-end-auto {
    grid-row-end: auto
}

.transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-none {
    transform: none
}

.origin-center {
    transform-origin: center
}

.origin-top {
    transform-origin: top
}

.origin-top-right {
    transform-origin: top right
}

.origin-right {
    transform-origin: right
}

.origin-bottom-right {
    transform-origin: bottom right
}

.origin-bottom {
    transform-origin: bottom
}

.origin-bottom-left {
    transform-origin: bottom left
}

.origin-left {
    transform-origin: left
}

.origin-top-left {
    transform-origin: top left
}

.scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0
}

.scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
}

.scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75
}

.scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9
}

.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
}

.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
}

.scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
}

.scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
}

.scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
}

.scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
}

.scale-x-0 {
    --tw-scale-x: 0
}

.scale-x-50 {
    --tw-scale-x: .5
}

.scale-x-75 {
    --tw-scale-x: .75
}

.scale-x-90 {
    --tw-scale-x: .9
}

.scale-x-95 {
    --tw-scale-x: .95
}

.scale-x-100 {
    --tw-scale-x: 1
}

.scale-x-105 {
    --tw-scale-x: 1.05
}

.scale-x-110 {
    --tw-scale-x: 1.1
}

.scale-x-125 {
    --tw-scale-x: 1.25
}

.scale-x-150 {
    --tw-scale-x: 1.5
}

.scale-y-0 {
    --tw-scale-y: 0
}

.scale-y-50 {
    --tw-scale-y: .5
}

.scale-y-75 {
    --tw-scale-y: .75
}

.scale-y-90 {
    --tw-scale-y: .9
}

.scale-y-95 {
    --tw-scale-y: .95
}

.scale-y-100 {
    --tw-scale-y: 1
}

.scale-y-105 {
    --tw-scale-y: 1.05
}

.scale-y-110 {
    --tw-scale-y: 1.1
}

.scale-y-125 {
    --tw-scale-y: 1.25
}

.scale-y-150 {
    --tw-scale-y: 1.5
}

.rotate-0 {
    --tw-rotate: 0deg
}

.rotate-1 {
    --tw-rotate: 1deg
}

.rotate-2 {
    --tw-rotate: 2deg
}

.rotate-3 {
    --tw-rotate: 3deg
}

.rotate-6 {
    --tw-rotate: 6deg
}

.rotate-12 {
    --tw-rotate: 12deg
}

.rotate-45 {
    --tw-rotate: 45deg
}

.rotate-90 {
    --tw-rotate: 90deg
}

.rotate-180 {
    --tw-rotate: 180deg
}

.-rotate-180 {
    --tw-rotate: -180deg
}

.-rotate-90 {
    --tw-rotate: -90deg
}

.-rotate-45 {
    --tw-rotate: -45deg
}

.-rotate-12 {
    --tw-rotate: -12deg
}

.-rotate-6 {
    --tw-rotate: -6deg
}

.-rotate-3 {
    --tw-rotate: -3deg
}

.-rotate-2 {
    --tw-rotate: -2deg
}

.-rotate-1 {
    --tw-rotate: -1deg
}

.translate-x-0 {
    --tw-translate-x: 0px
}

.translate-x-1 {
    --tw-translate-x: 4px
}

.translate-x-2 {
    --tw-translate-x: 8px
}

.translate-x-3 {
    --tw-translate-x: 12px
}

.translate-x-4 {
    --tw-translate-x: 16px
}

.translate-x-5 {
    --tw-translate-x: 20px
}

.translate-x-6 {
    --tw-translate-x: 24px
}

.translate-x-7 {
    --tw-translate-x: 28px
}

.translate-x-8 {
    --tw-translate-x: 32px
}

.translate-x-9 {
    --tw-translate-x: 36px
}

.translate-x-10 {
    --tw-translate-x: 40px
}

.translate-x-11 {
    --tw-translate-x: 44px
}

.translate-x-12 {
    --tw-translate-x: 48px
}

.translate-x-14 {
    --tw-translate-x: 56px
}

.translate-x-16 {
    --tw-translate-x: 64px
}

.translate-x-20 {
    --tw-translate-x: 80px
}

.translate-x-24 {
    --tw-translate-x: 96px
}

.translate-x-28 {
    --tw-translate-x: 112px
}

.translate-x-32 {
    --tw-translate-x: 128px
}

.translate-x-36 {
    --tw-translate-x: 144px
}

.translate-x-40 {
    --tw-translate-x: 160px
}

.translate-x-44 {
    --tw-translate-x: 176px
}

.translate-x-48 {
    --tw-translate-x: 192px
}

.translate-x-52 {
    --tw-translate-x: 208px
}

.translate-x-56 {
    --tw-translate-x: 224px
}

.translate-x-60 {
    --tw-translate-x: 240px
}

.translate-x-64 {
    --tw-translate-x: 256px
}

.translate-x-72 {
    --tw-translate-x: 288px
}

.translate-x-80 {
    --tw-translate-x: 320px
}

.translate-x-96 {
    --tw-translate-x: 384px
}

.translate-x-px {
    --tw-translate-x: 1px
}

.translate-x-0d5 {
    --tw-translate-x: 2px
}

.translate-x-1d5 {
    --tw-translate-x: 6px
}

.translate-x-2d5 {
    --tw-translate-x: 10px
}

.translate-x-3d5 {
    --tw-translate-x: 14px
}

.-translate-x-0 {
    --tw-translate-x: 0px
}

.-translate-x-1 {
    --tw-translate-x: -4px
}

.-translate-x-2 {
    --tw-translate-x: -8px
}

.-translate-x-3 {
    --tw-translate-x: -12px
}

.-translate-x-4 {
    --tw-translate-x: -16px
}

.-translate-x-5 {
    --tw-translate-x: -20px
}

.-translate-x-6 {
    --tw-translate-x: -24px
}

.-translate-x-7 {
    --tw-translate-x: -28px
}

.-translate-x-8 {
    --tw-translate-x: -32px
}

.-translate-x-9 {
    --tw-translate-x: -36px
}

.-translate-x-10 {
    --tw-translate-x: -40px
}

.-translate-x-11 {
    --tw-translate-x: -44px
}

.-translate-x-12 {
    --tw-translate-x: -48px
}

.-translate-x-14 {
    --tw-translate-x: -56px
}

.-translate-x-16 {
    --tw-translate-x: -64px
}

.-translate-x-20 {
    --tw-translate-x: -80px
}

.-translate-x-24 {
    --tw-translate-x: -96px
}

.-translate-x-28 {
    --tw-translate-x: -112px
}

.-translate-x-32 {
    --tw-translate-x: -128px
}

.-translate-x-36 {
    --tw-translate-x: -144px
}

.-translate-x-40 {
    --tw-translate-x: -160px
}

.-translate-x-44 {
    --tw-translate-x: -176px
}

.-translate-x-48 {
    --tw-translate-x: -192px
}

.-translate-x-52 {
    --tw-translate-x: -208px
}

.-translate-x-56 {
    --tw-translate-x: -224px
}

.-translate-x-60 {
    --tw-translate-x: -240px
}

.-translate-x-64 {
    --tw-translate-x: -256px
}

.-translate-x-72 {
    --tw-translate-x: -288px
}

.-translate-x-80 {
    --tw-translate-x: -320px
}

.-translate-x-96 {
    --tw-translate-x: -384px
}

.-translate-x-px {
    --tw-translate-x: -1px
}

.-translate-x-0d5 {
    --tw-translate-x: -2px
}

.-translate-x-1d5 {
    --tw-translate-x: -6px
}

.-translate-x-2d5 {
    --tw-translate-x: -10px
}

.-translate-x-3d5 {
    --tw-translate-x: -14px
}

.translate-x-1_2 {
    --tw-translate-x: 50%
}

.translate-x-1_3 {
    --tw-translate-x: 33.333333%
}

.translate-x-2_3 {
    --tw-translate-x: 66.666667%
}

.translate-x-1_4 {
    --tw-translate-x: 25%
}

.translate-x-2_4 {
    --tw-translate-x: 50%
}

.translate-x-3_4 {
    --tw-translate-x: 75%
}

.translate-x-full {
    --tw-translate-x: 100%
}

.-translate-x-1_2 {
    --tw-translate-x: -50%
}

.-translate-x-1_3 {
    --tw-translate-x: -33.333333%
}

.-translate-x-2_3 {
    --tw-translate-x: -66.666667%
}

.-translate-x-1_4 {
    --tw-translate-x: -25%
}

.-translate-x-2_4 {
    --tw-translate-x: -50%
}

.-translate-x-3_4 {
    --tw-translate-x: -75%
}

.-translate-x-full {
    --tw-translate-x: -100%
}

.translate-y-0 {
    --tw-translate-y: 0px
}

.translate-y-1 {
    --tw-translate-y: 4px
}

.translate-y-2 {
    --tw-translate-y: 8px
}

.translate-y-3 {
    --tw-translate-y: 12px
}

.translate-y-4 {
    --tw-translate-y: 16px
}

.translate-y-5 {
    --tw-translate-y: 20px
}

.translate-y-6 {
    --tw-translate-y: 24px
}

.translate-y-7 {
    --tw-translate-y: 28px
}

.translate-y-8 {
    --tw-translate-y: 32px
}

.translate-y-9 {
    --tw-translate-y: 36px
}

.translate-y-10 {
    --tw-translate-y: 40px
}

.translate-y-11 {
    --tw-translate-y: 44px
}

.translate-y-12 {
    --tw-translate-y: 48px
}

.translate-y-14 {
    --tw-translate-y: 56px
}

.translate-y-16 {
    --tw-translate-y: 64px
}

.translate-y-20 {
    --tw-translate-y: 80px
}

.translate-y-24 {
    --tw-translate-y: 96px
}

.translate-y-28 {
    --tw-translate-y: 112px
}

.translate-y-32 {
    --tw-translate-y: 128px
}

.translate-y-36 {
    --tw-translate-y: 144px
}

.translate-y-40 {
    --tw-translate-y: 160px
}

.translate-y-44 {
    --tw-translate-y: 176px
}

.translate-y-48 {
    --tw-translate-y: 192px
}

.translate-y-52 {
    --tw-translate-y: 208px
}

.translate-y-56 {
    --tw-translate-y: 224px
}

.translate-y-60 {
    --tw-translate-y: 240px
}

.translate-y-64 {
    --tw-translate-y: 256px
}

.translate-y-72 {
    --tw-translate-y: 288px
}

.translate-y-80 {
    --tw-translate-y: 320px
}

.translate-y-96 {
    --tw-translate-y: 384px
}

.translate-y-px {
    --tw-translate-y: 1px
}

.translate-y-0d5 {
    --tw-translate-y: 2px
}

.translate-y-1d5 {
    --tw-translate-y: 6px
}

.translate-y-2d5 {
    --tw-translate-y: 10px
}

.translate-y-3d5 {
    --tw-translate-y: 14px
}

.-translate-y-0 {
    --tw-translate-y: 0px
}

.-translate-y-1 {
    --tw-translate-y: -4px
}

.-translate-y-2 {
    --tw-translate-y: -8px
}

.-translate-y-3 {
    --tw-translate-y: -12px
}

.-translate-y-4 {
    --tw-translate-y: -16px
}

.-translate-y-5 {
    --tw-translate-y: -20px
}

.-translate-y-6 {
    --tw-translate-y: -24px
}

.-translate-y-7 {
    --tw-translate-y: -28px
}

.-translate-y-8 {
    --tw-translate-y: -32px
}

.-translate-y-9 {
    --tw-translate-y: -36px
}

.-translate-y-10 {
    --tw-translate-y: -40px
}

.-translate-y-11 {
    --tw-translate-y: -44px
}

.-translate-y-12 {
    --tw-translate-y: -48px
}

.-translate-y-14 {
    --tw-translate-y: -56px
}

.-translate-y-16 {
    --tw-translate-y: -64px
}

.-translate-y-20 {
    --tw-translate-y: -80px
}

.-translate-y-24 {
    --tw-translate-y: -96px
}

.-translate-y-28 {
    --tw-translate-y: -112px
}

.-translate-y-32 {
    --tw-translate-y: -128px
}

.-translate-y-36 {
    --tw-translate-y: -144px
}

.-translate-y-40 {
    --tw-translate-y: -160px
}

.-translate-y-44 {
    --tw-translate-y: -176px
}

.-translate-y-48 {
    --tw-translate-y: -192px
}

.-translate-y-52 {
    --tw-translate-y: -208px
}

.-translate-y-56 {
    --tw-translate-y: -224px
}

.-translate-y-60 {
    --tw-translate-y: -240px
}

.-translate-y-64 {
    --tw-translate-y: -256px
}

.-translate-y-72 {
    --tw-translate-y: -288px
}

.-translate-y-80 {
    --tw-translate-y: -320px
}

.-translate-y-96 {
    --tw-translate-y: -384px
}

.-translate-y-px {
    --tw-translate-y: -1px
}

.-translate-y-0d5 {
    --tw-translate-y: -2px
}

.-translate-y-1d5 {
    --tw-translate-y: -6px
}

.-translate-y-2d5 {
    --tw-translate-y: -10px
}

.-translate-y-3d5 {
    --tw-translate-y: -14px
}

.translate-y-1_2 {
    --tw-translate-y: 50%
}

.translate-y-1_3 {
    --tw-translate-y: 33.333333%
}

.translate-y-2_3 {
    --tw-translate-y: 66.666667%
}

.translate-y-1_4 {
    --tw-translate-y: 25%
}

.translate-y-2_4 {
    --tw-translate-y: 50%
}

.translate-y-3_4 {
    --tw-translate-y: 75%
}

.translate-y-full {
    --tw-translate-y: 100%
}

.-translate-y-1_2 {
    --tw-translate-y: -50%
}

.-translate-y-1_3 {
    --tw-translate-y: -33.333333%
}

.-translate-y-2_3 {
    --tw-translate-y: -66.666667%
}

.-translate-y-1_4 {
    --tw-translate-y: -25%
}

.-translate-y-2_4 {
    --tw-translate-y: -50%
}

.-translate-y-3_4 {
    --tw-translate-y: -75%
}

.-translate-y-full {
    --tw-translate-y: -100%
}

.skew-x-0 {
    --tw-skew-x: 0deg
}

.skew-x-1 {
    --tw-skew-x: 1deg
}

.skew-x-2 {
    --tw-skew-x: 2deg
}

.skew-x-3 {
    --tw-skew-x: 3deg
}

.skew-x-6 {
    --tw-skew-x: 6deg
}

.skew-x-12 {
    --tw-skew-x: 12deg
}

.-skew-x-12 {
    --tw-skew-x: -12deg
}

.-skew-x-6 {
    --tw-skew-x: -6deg
}

.-skew-x-3 {
    --tw-skew-x: -3deg
}

.-skew-x-2 {
    --tw-skew-x: -2deg
}

.-skew-x-1 {
    --tw-skew-x: -1deg
}

.skew-y-0 {
    --tw-skew-y: 0deg
}

.skew-y-1 {
    --tw-skew-y: 1deg
}

.skew-y-2 {
    --tw-skew-y: 2deg
}

.skew-y-3 {
    --tw-skew-y: 3deg
}

.skew-y-6 {
    --tw-skew-y: 6deg
}

.skew-y-12 {
    --tw-skew-y: 12deg
}

.-skew-y-12 {
    --tw-skew-y: -12deg
}

.-skew-y-6 {
    --tw-skew-y: -6deg
}

.-skew-y-3 {
    --tw-skew-y: -3deg
}

.-skew-y-2 {
    --tw-skew-y: -2deg
}

.-skew-y-1 {
    --tw-skew-y: -1deg
}

.transition-none {
    transition-property: none
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.ease-linear {
    transition-timing-function: linear
}

.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-75 {
    transition-duration: 75ms
}

.duration-100 {
    transition-duration: 100ms
}

.duration-150 {
    transition-duration: 150ms
}

.duration-200 {
    transition-duration: 200ms
}

.duration-300 {
    transition-duration: 300ms
}

.duration-500 {
    transition-duration: 500ms
}

.duration-700 {
    transition-duration: 700ms
}

.duration-1000 {
    transition-duration: 1000ms
}

.delay-75 {
    transition-delay: 75ms
}

.delay-100 {
    transition-delay: 100ms
}

.delay-150 {
    transition-delay: 150ms
}

.delay-200 {
    transition-delay: 200ms
}

.delay-300 {
    transition-delay: 300ms
}

.delay-500 {
    transition-delay: 500ms
}

.delay-700 {
    transition-delay: 700ms
}

.delay-1000 {
    transition-delay: 1000ms
}

.animate-none {
    animation: none
}

.animate-spin {
    animation: spin 1s linear infinite
}

.animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.animate-bounce {
    animation: bounce 1s infinite
}


/*# sourceMappingURL=tailwind.css.map */
