/* 后台页面公用样式 */
.admin-layout {
  /* 页面标题 */
  /* 控制按钮s */
  /* 控制按钮e */
  /* 表格s */
  /* 表格e */
  /* 新增/编辑页面按钮样式 */
  /* 额外按钮样式 */
  /* antd表单控件样式 */
  /* 详情页通用s */
  /* 详情页通用e */
}
.admin-layout .page-title {
  font-size: 20px;
  line-height: 28px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.admin-layout .ant-btn {
  border-radius: 3px;
  color: #999999;
}
.admin-layout .ant-btn-primary {
  background-color: #2768b8;
  color: #FFFFFF;
}
.admin-layout .ant-btn-primary:hover {
  opacity: 0.9;
}
.admin-layout .ant-btn-dangerous {
  background-color: #FF3D5A;
  color: #FFFFFF;
}
.admin-layout .ant-btn-dangerous:hover {
  opacity: 0.9;
}
.admin-layout .ant-table {
  /* 表头 */
  /* 表体 */
  /* 单选 */
}
.admin-layout .ant-table .ant-table-thead th {
  background-color: #F0F2F7;
  font-size: 16px;
  color: #666666;
  font-weight: 500;
  line-height: 20px;
  padding: 14px;
}
.admin-layout .ant-table .ant-table-thead th::before {
  display: none;
}
.admin-layout .ant-table .ant-table-tbody {
  /* 扩展表格外容器 */
  /* 状态列 */
  /* 操作列 */
  /* 开关 */
}
.admin-layout .ant-table .ant-table-tbody tr[data-row-key]:hover td {
  background-color: #F0F2F7;
}
.admin-layout .ant-table .ant-table-tbody tr[data-row-key]:hover td[class="ant-table-cell"] {
  color: #2768b8;
}
.admin-layout .ant-table .ant-table-tbody tr[data-row-key]:hover td[class="ant-table-cell ant-table-cell-ellipsis"] {
  color: #2768b8;
}
.admin-layout .ant-table .ant-table-tbody .ant-table-tbody tr td {
  padding: 0px;
}
.admin-layout .ant-table .ant-table-tbody td {
  line-height: 20px;
  padding: 14px 14px 13px;
  box-sizing: border-box;
  border-bottom: 1px solid #D9D9D9;
}
.admin-layout .ant-table .ant-table-tbody .status span::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 9px;
  position: relative;
  top: 1px;
}
.admin-layout .ant-table .ant-table-tbody .status .default {
  color: #999999;
}
.admin-layout .ant-table .ant-table-tbody .status .default::before {
  background-color: #999999;
}
.admin-layout .ant-table .ant-table-tbody .status .success {
  color: #0BC430;
}
.admin-layout .ant-table .ant-table-tbody .status .success::before {
  background-color: #0BC430;
}
.admin-layout .ant-table .ant-table-tbody .status .primary {
  color: #2768b8;
}
.admin-layout .ant-table .ant-table-tbody .status .primary::before {
  background-color: #2768b8;
}
.admin-layout .ant-table .ant-table-tbody .status .danger {
  color: #FF3D5A;
}
.admin-layout .ant-table .ant-table-tbody .status .danger::before {
  background-color: #FF3D5A;
}
.admin-layout .ant-table .ant-table-tbody .tools {
  padding: 9px 14px 8px;
}
.admin-layout .ant-table .ant-table-tbody .tools .ant-btn {
  background-color: transparent;
  padding: 0;
}
.admin-layout .ant-table .ant-table-tbody .tools .ant-btn:hover {
  opacity: 0.8;
}
.admin-layout .ant-table .ant-table-tbody .tools .ant-btn:not(:last-child) {
  margin-right: 25px;
}
.admin-layout .ant-table .ant-table-tbody .tools .default {
  color: #333333;
}
.admin-layout .ant-table .ant-table-tbody .tools .primary {
  color: #2768b8;
}
.admin-layout .ant-table .ant-table-tbody .tools .danger {
  color: #FF3D5A;
}
.admin-layout .ant-table .ant-table-tbody .ant-switch-checked {
  background-color: #0052D9;
}
.admin-layout .ant-table .ant-table-selection-column {
  padding: 14px 20px;
}
.admin-layout .add-btn-box {
  display: flex;
  justify-content: center;
}
.admin-layout .add-btn-box .btn {
  min-width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 8px;
  border: 1px solid #2768b8;
  border-radius: 5px;
  font-size: 18px;
  color: #2768b8;
  margin-right: 24px;
  cursor: pointer;
}
.admin-layout .add-btn-box .btn:hover {
  opacity: 0.8;
}
.admin-layout .add-btn-box .btn:last-child {
  margin-right: 0;
}
.admin-layout .add-btn-box .btn-primary {
  color: #FFFFFF;
  background-color: #2768b8;
  cursor: pointer;
}
.admin-layout .primary-text-btn {
  color: #2768b8;
}
.admin-layout .primary-text-btn:hover {
  color: #2768b8;
  opacity: 0.8;
}
.admin-layout .ant-input {
  border-radius: 3px;
}
.admin-layout .admin-page-detail {
  /* 内容容器 */
}
.admin-layout .admin-page-detail .page-box {
  background-color: #FFFFFF;
  border-radius: 3px;
  margin-top: 24px;
  /* 内容标题 */
  /* 内容内容 */
  /* 内容额外 */
  /* 表格容器 */
}
.admin-layout .admin-page-detail .page-box:first-child {
  margin-top: 0;
}
.admin-layout .admin-page-detail .page-box > .page-box-title {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  border-bottom: 1px solid #D9D9D9;
}
.admin-layout .admin-page-detail .page-box > .page-box-content {
  padding: 24px;
  /* 内容行 */
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box:last-child {
  margin-bottom: 0;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  /* 行标题 */
  /* 行label */
  /* 行信息 */
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .title {
  font-weight: 700;
  color: #333333;
  display: flex;
  align-items: center;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .title-primary::before {
  content: '';
  display: block;
  height: 8px;
  width: 8px;
  background: #2768b8;
  border-radius: 50%;
  margin-right: 6px;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .label {
  color: #666666;
  margin-right: 10px;
  flex-shrink: 0;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .content {
  color: #333333;
  /* 图片 */
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .content .image-box {
  width: 140px;
  height: 80px;
  background-color: #F7F7F7;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .content .image-box img {
  width: 140px;
  height: 80px;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .content .danger {
  color: #FF3D5A;
}
.admin-layout .admin-page-detail .page-box > .page-box-content .row-box > .item .content .primary {
  color: #2768b8;
}
.admin-layout .admin-page-detail .page-box > .page-box-row {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #D9D9D9;
}
.admin-layout .admin-page-detail .page-box .table-box {
  padding: 24px;
  /* 分页容器 */
}
.admin-layout .admin-page-detail .page-box .table-box .pagination-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
  margin-top: 24px;
}
.admin-layout .admin-page-detail .page-box .table-box .pagination-box .info {
  font-size: 14px;
  color: #333333;
}
/* 按钮样式 S */
.ant-btn.btn-bg-blue {
  padding: 0 16px;
  min-width: 87px;
  color: #fff;
  background: #2768b8;
  border: none;
  box-sizing: border-box;
}
.ant-btn.btn-bg-blue::after {
  display: none;
}
.ant-btn.btn-bg-orange {
  padding: 0 16px;
  min-width: 87px;
  color: #fff;
  background: #f5971d;
  border: none;
  box-sizing: border-box;
}
.ant-btn.btn-bg-orange::after {
  display: none;
}
.ant-btn.btn-bg-green {
  padding: 0 16px;
  min-width: 87px;
  color: #fff;
  background: #0bc430;
  border: none;
  box-sizing: border-box;
}
.ant-btn.btn-bg-green::after {
  display: none;
}
.ant-btn.btn-bg-red {
  padding: 0 16px;
  min-width: 87px;
  color: #fff;
  background: #ff3d5a;
  border: none;
  box-sizing: border-box;
}
.ant-btn.btn-bg-red::after {
  display: none;
}
.ant-btn.btn-bg-gray {
  padding: 0 16px;
  min-width: 87px;
  color: #fff;
  background: #bfbfbf;
  border: none;
  box-sizing: border-box;
}
.ant-btn.btn-bg-gray:hover {
  background: #2768b8;
}
.ant-btn.btn-bg-gray::after {
  display: none;
}
.ant-btn.btn-red {
  padding: 0 16px;
  min-width: 87px;
  color: #ff3d5a;
  background: #fff;
  border: 1px solid #ff3d5a;
  box-sizing: border-box;
}
.ant-btn.btn-blue {
  padding: 0 16px;
  min-width: 87px;
  color: #2768b8;
  background: #fff;
  border: 1px solid #2768b8;
  box-sizing: border-box;
}
.ant-btn.btn-orange {
  padding: 0 16px;
  min-width: 87px;
  color: #F5971D;
  background: #fff;
  border: 1px solid #F5971D;
  box-sizing: border-box;
}
.ant-btn.btn-gray {
  padding: 0 16px;
  min-width: 87px;
  color: #999;
  background: #fff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
/* 按钮样式 E */
