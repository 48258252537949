/* 后台页面公用样式 */
.admin-layout {
  min-width: 1280px;
  /* 头部容器 */
  /* 内容及左侧导航容器 */
}
.admin-layout .ant-layout-header {
  width: 100%;
  display: flex;
  background-color: #FFFFFF;
  padding: 0 40px;
  z-index: 1000;
  left: 0;
  top: 0;
  /* 头部 */
}
.admin-layout .ant-layout-header .admin-layout-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* 头部右侧 */
}
.admin-layout .ant-layout-header .admin-layout-header .logo {
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  color: #3662EB;
  margin-right: 120px;
  flex-shrink: 0;
}
.admin-layout .ant-layout-header .admin-layout-header .header-right {
  display: flex;
}
.admin-layout .ant-layout-header .admin-layout-header .header-right .header-user {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.admin-layout .ant-layout-header .admin-layout-header .header-right .header-user .item {
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.admin-layout .ant-layout-header .admin-layout-header .header-right .header-user .item .anticon {
  font-size: 24px;
}
.admin-layout .ant-layout-header .admin-layout-header .header-right .header-user .ant-dropdown-trigger {
  display: block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.admin-layout .ant-layout-has-sider {
  background-color: #F3F5F7;
  /* 左侧导航容器 */
  /* 内容容器 */
}
.admin-layout .ant-layout-has-sider .ant-layout-sider {
  background: #FFFFFF;
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children {
  padding-bottom: 48px;
  background: #FFFFFF;
  /* 左侧导航控制 */
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control {
  padding: 0 30px;
  position: fixed;
  height: 48px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  bottom: 30px;
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control .anticon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: color, transform 0.3s;
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control .anticon:hover {
  color: #0052D9;
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control .anticon-close {
  transform: rotate(-90deg);
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control-available .anticon {
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}
.admin-layout .ant-layout-has-sider .ant-layout-sider .ant-layout-sider-children .side-menu-control-available .anticon:hover {
  color: rgba(0, 0, 0, 0.3);
}
.admin-layout .ant-layout-has-sider .ant-layout-content {
  padding: 0 24px;
  /* 内容 */
}
.admin-layout .ant-layout-has-sider .ant-layout-content .main-container {
  min-height: calc(100Vh - 164px);
}
