/* 页面公用样式 */
body {
  background-color: #F3F5F7;
}
/* 字体s */
@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(../fonts/YouSheBiaoTiHei.TTF);
}
/* 字体e */
/* 前台一般容器s */
.server-main-container {
  padding: 0 40px;
  min-width: 1280px;
  margin: 0 auto;
}
.server-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  min-width: 1200px;
}
/* 前台一般容器e */
/* 按钮样式s */
.primary-text-btn {
  color: #2768b8;
}
.primary-text-btn:hover {
  color: #2768b8;
  opacity: 0.8;
}
.primary-text-btn:focus {
  color: #2768b8;
}
.edit-avatar-btn {
  color: #2768b8;
  border: 1px solid #2768b8;
  border-radius: 5px;
  height: 40px;
  padding: 7px 24px;
  font-size: 18px;
  line-height: 25px;
}
.edit-avatar-btn:focus,
.edit-avatar-btn:hover {
  color: #2768b8;
  border: 1px solid #2768b8;
}
.edit-avatar-btn:hover {
  opacity: 0.8;
}
.edit-avatar-btn::after {
  display: none;
}
.save-message-btn {
  background-color: #2768b8;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 7px 24px;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
}
.save-message-btn:hover {
  opacity: 0.8;
}
.save-message-btn:hover,
.save-message-btn:focus {
  background-color: #2768b8;
  color: #FFFFFF;
  border: 0;
}
.save-message-btn::after {
  display: none;
}
/* 按钮样式e */
/* antd表单控件通用样式修改 */
/* 日期选择s */
.ant-picker:hover {
  border: 1px solid #2768b8;
}
.ant-picker-range .ant-picker-active-bar {
  background: #2768b8;
}
.ant-picker-focused {
  border-color: #2768b8;
  box-shadow: none;
}
/* 日期选择e */
/* 输入框s */
.ant-input:hover {
  border: 1px solid #2768b8;
}
.ant-input:focus {
  border: 1px solid #2768b8;
  box-shadow: none;
}
/* 输入框e */
/* 选择框s */
.ant-select:hover .ant-select-selector {
  border-color: #2768b8 !important;
}
.ant-select-focused .ant-select-selector {
  border-color: #2768b8 !important;
  box-shadow: none !important;
}
/* 选择框e */
/* 多级选框s */
.ant-cascader-picker:hover .ant-cascader-input {
  border-color: #2768b8 !important;
}
.ant-cascader-picker:focus .ant-cascader-input {
  box-shadow: none !important;
  border-color: #2768b8 !important;
}
.ant-cascader-picker-focused .ant-cascader-input {
  border-color: #2768b8 !important;
  box-shadow: none !important;
}
.ant-cascader-picker-disabled:hover .ant-cascader-input,
.ant-cascader-picker-disabled:focus .ant-cascader-input {
  border-color: #d9d9d9 !important;
}
/* 多级选框e */
/* 搜索框s */
.ant-input-affix-wrapper:hover {
  border-color: #2768b8 !important;
}
.ant-input-affix-wrapper-focused {
  border-color: #2768b8;
  box-shadow: none;
}
.ant-form-item-has-error:not(.ant-input-affix-wrapper-disabled) .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f !important;
}
/* 搜索框e */
/* 提示弹框样式s */
.health-nomal-modal .anticon-info-circle {
  color: #0052D9 !important;
}
.health-nomal-modal .ant-modal-confirm-content {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}
.health-nomal-modal .ant-modal-confirm-btns .ant-btn {
  background: #e7e7e7;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}
.health-nomal-modal .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #0052D9;
  color: rgba(255, 255, 255, 0.9);
}
/* 提示弹框样式e */
/* 后台表格拖拽样式s */
.row-dragging {
  background-color: #F0F2F7;
  overflow: hidden;
  border: 1px solid #D9D9D9;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.row-dragging td {
  padding: 10px 20px;
}
.row-dragging td:first-child {
  display: none;
}
/* 后台表格拖拽样式e */
.flex-box {
  display: flex;
}
