/* 登录页面 */
.user-login {
  /* 登录表单 */
}
.user-login > .form-login {
  position: relative;
  width: 480px;
  background-color: rgba(255, 255, 255, 0.3);
  max-width: 100%;
  padding: 30px 30px 40px 30px;
  margin: 160px auto 0;
  overflow: hidden;
  border-radius: 10px;
  /* 表单标题 */
  /* 表单项 */
  /* 登录按钮 */
}
.user-login > .form-login > .title {
  font-size: 26px;
  color: #24c3f3;
  margin: 0 auto 30px auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
}
.user-login > .form-login > .form-item {
  position: relative;
  height: 52px;
  font-size: 14px;
  /* 容器样式 */
  /* 表单项图标样式 */
  /* input输入框 */
}
.user-login > .form-login > .form-item input {
  height: 35px;
}
.user-login > .form-login > .form-item .ant-input-affix-wrapper {
  border: 0;
}
.user-login > .form-login > .form-item .ant-input-prefix {
  font-size: 18px;
  color: #889aa4;
  margin-right: 8px;
}
.user-login > .form-login > .form-item .ant-input {
  /* 修正谷歌浏览器填充 */
}
.user-login > .form-login > .form-item .ant-input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
.user-login > .form-login > .login-btn {
  width: 100%;
  border: 0;
  height: 40px;
  border-radius: 20px;
}
.login-page {
  margin: 28px auto 0;
  width: 500px;
}
.login-page .ant-tabs-top .ant-tabs-nav {
  margin-bottom: 40px;
}
.login-page .ant-tabs-top .ant-tabs-nav::before {
  border-bottom: none;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  flex: 1;
  padding: 26px 0 18px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  width: 100%;
  text-align: center;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2768b8;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
  display: flex;
  justify-content: center;
  background: inherit;
}
.login-page .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar::before {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background-color: #2768b8;
}
.login-page .login-form .ant-form-item {
  margin-bottom: 20px;
}
.login-page .login-form .ant-form-item .ant-form-item-label {
  padding-bottom: 10px;
}
.login-page .login-form .ant-form-item .ant-form-item-label label {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.login-page .login-form .ant-form-item .ant-form-item-control-input input {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: #eaebf2;
  border: none;
  box-shadow: none;
}
.login-page .login-form .ant-form-item .ant-input-password {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  background: #eaebf2;
  border: none;
}
.login-page .login-form .ant-form-item .ant-input-password:hover {
  background: #eaebf2;
}
.login-page .login-form .ant-form-item .ant-input-password.ant-input-affix-wrapper-focused {
  border: none;
  box-shadow: none;
}
.login-page .login-form .ant-form-item .verify-code {
  position: relative;
}
.login-page .login-form .ant-form-item .verify-code input {
  padding-right: 130px;
}
.login-page .login-form .ant-form-item .verify-code .verify-code-img {
  position: absolute;
  right: 0;
  padding: 0;
  width: 120px;
  height: 40px;
  line-height: 40px;
  background: #bfc1cf;
  border: none;
  box-shadow: none;
}
.login-page .login-form .ant-form-item .verify-code .verify-code-img::after {
  display: none;
}
.login-page .login-form .ant-form-item .verify-code .verify-code-img img {
  display: block;
  width: 100%;
  height: 100%;
}
.login-page .login-form .ant-form-item .login-form-button {
  margin-top: 20px;
  padding: 0;
  width: 100%;
  height: 40px;
  background: #2768b8;
  border: none;
  box-shadow: 0px 4px 16px 0px rgba(10, 54, 174, 0.16);
}
.login-page .login-form .ant-form-item .login-form-button span {
  font-size: 16px;
  color: #ffffff;
}
.login-page .login-form .reset-password {
  margin: -15px 0 0;
}
.login-page .login-form .reset-password .reset-password-btn {
  padding: 0;
  width: 100%;
  font-size: 16px;
  color: #2768b8;
  text-align: right;
  background: inherit;
}
.login-page .login-form .register a {
  display: block;
  font-size: 16px;
  color: #2768b8;
  line-height: 40px;
  text-align: center;
}
.login-page .login-form .ant-divider {
  margin: 30px 0 40px;
}
.login-page .login-form .browser {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  color: #999999;
}
.login-page .login-form .browser p {
  margin: 0;
}
.login-page .login-form .browser .ant-space-item {
  height: 33px;
  line-height: unset;
}
.login-page .login-form .browser .ant-space-item .ant-btn-link {
  margin-left: 24px;
  padding: 0;
  height: auto;
  border: none;
}
.login-page .login-form .browser .ant-space-item .ant-btn-link img {
  display: block;
  width: 33px;
  height: 33px;
}
