// 公共样式
.foot-pagination-comp {
  position: relative;
  display: flex;
  align-items: center;
  // height: 64px;

  .ant-pagination {
    height: auto;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 32px;
    height: 32px;
    line-height: 30px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    box-sizing: border-box;

    a,
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    line-height: 32px;
    background: inherit;

    span {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .ant-pagination-options-quick-jumper {
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 32px;

    input {
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      background: #ffffff;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }

  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover,
  .ant-pagination-item-active,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):hover
    .ant-select-selector,
  .ant-select:not(.ant-select-disabled).ant-select-focused .ant-select-selector,
  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-focused
    .ant-select-selector,
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #2768b8;
    box-shadow: none;
  }

  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: #2768b8;
  }

  .ant-pagination-item.ant-pagination-item-active,
  .ant-pagination-item.ant-pagination-item-active:hover {
    background: #2768b8;

    a {
      color: #fff;
    }
  }
}

// 前台分页
.default-pagination {
  background-color: #fff;

  .ant-pagination-prev,
  .ant-pagination-next {
    border-color: #fff;
  }
}

// 后台分页
.admin-pagination {
  padding-left: 116px;
  // background-color: #F9F9F9;

  .ant-pagination-prev,
  .ant-pagination-next {
    border-color: #f9f9f9;
  }

  .ant-pagination-options {
    min-height: 32px;
  }

  .ant-pagination-options-size-changer.ant-select {
    position: absolute;
    left: 0;
    color: rgba(0, 0, 0, 0.6);

    .ant-select-arrow {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .ant-select:not(.ant-select-disabled).ant-select-open .ant-select-arrow,
  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-open
    .ant-select-arrow {
    color: rgba(0, 0, 0, 0.25);
  }
}

// 弹框分页
.pop-pagination {
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    line-height: 22px;
    background: #ffffff;
    border: 1px solid #e6e6e6;

    a,
    span {
      font-size: 12px;
      font-weight: 500;
      color: #b3b3b3;
    }
  }

  .ant-pagination-options-quick-jumper {
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #b3b3b3;
    line-height: 24px;

    input {
      height: 24px;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
    }
  }
}
