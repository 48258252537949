.component-searchInp {
  .ant-input-affix-wrapper {
    height: 50px;
    border: 1px solid #dddddd;
    &::placeholder {
      color: #999999;
    }
    .ant-input-suffix {
      color: #999999;
      font-size: 16px;
      .anticon-search{
        svg {
          position: relative;
          top: 1px;
        }
      }
    }
    .ant-input-clear-icon {
      margin-right: 15px;
    }
  }
}
.component-admin-searchInp {
  .ant-input-suffix {
    color: #999999;
    .anticon-search{
      svg {
        position: relative;
        top: 1px;
      }
    }
  }
}