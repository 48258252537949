html,body {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
}
/* Width */
.w-full {
  width: 100%;
}
.w-20 {
  width: 80px;
}
/* Height */
.h-8 {
  height: 32px;
}
.h-10 {
  height: 40px;
}
.h-15 {
  height: 60px;
}
.h-20 {
  height: 80px;
}
.h-25 {
  height: 100px;
}
.h-40 {
  height: 160px;
}
.hidden {
  display: none !important;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
/* Align Items */
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
/* Align Self */
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
/* Flex */
.flex-1 {
  flex: 1 1 0%;
}
.flex-2 {
  flex: 2 2 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
/* Flex Direction */
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
/* Padding And Margin*/
.generate-padding-margin(@n, @i) when (@i <=@n) {
  .p-@{i} {
    padding: @i * 0.25rem;
  }
  .px-@{i} {
    padding-right: @i * 0.25rem;
    padding-left: @i * 0.25rem;
  }
  .py-@{i} {
    padding-top: @i * 0.25rem;
    padding-bottom: @i * 0.25rem;
  }

  .pl-@{i} {
    padding-left: @i * 0.25rem;
  }
  .pr-@{i} {
    padding-right: @i * 0.25rem;
  }
  .pt-@{i} {
    padding-top: @i * 0.25rem;
  }
  .pb-@{i} {
    padding-bottom: @i * 0.25rem;
  }
  .mt-@{i} {
    margin-top: @i * 0.25rem;
  }
  .mr-@{i} {
    margin-right: @i * 0.25rem;
  }
  .mb-@{i} {
    margin-bottom: @i * 0.25rem;
  }
  .ml-@{i} {
    margin-left: @i * 0.25rem;
  }
  .mx-@{i} {
    margin-right: @i * 0.25rem;
    margin-left: @i * 0.25rem;
  }
  .my-@{i} {
    margin-top: @i * 0.25rem;
    margin-bottom: @i * 0.25rem;
  }
  .m-@{i} {
    margin: @i * 0.25rem;
  }
  .generate-padding-margin(@n, @i + 1);
}
.generate-padding-margin(10, 0);
.ml-auto {
  margin-left: auto;
}
/* Background Color */
.bg-white {
  background-color: #FFF;
}
.bg-blue {
  background-color: #2768b8;
}
.bg-red {
  background-color: #FF3D5A;
}
.bg-red-200 {
  background-color: #FFF2F4;
}
.bg-green {
  background-color: #0BC430;
}
.bg-gray {
  background-color: #BFBFBF;
}
/* Text Color */
.text-white {
  color: #FFF;
}
.text-blue {
  color: #2768b8;
}
.text-red {
  color: #FF3D5A;
}
.text-green {
  color: #0BC430;
}
.text-gray-333 {
  color: #333
}
.text-gray-666 {
  color: #666
}
.text-gray-999 {
  color: #999
}
/* Font Size */
.text-xs {
	font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
/* Font Weight */
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
/* Border Radius */
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
/* Text Overflow */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.text-clip {
  text-overflow: clip;
}
/* Bottom */
.border-b-1 {
  border-bottom: 1px #D9D9D9 solid;
}
.border-t-1 {
  border-top: 1px #D9D9D9 solid;
}
/* Cursor */
.cursor-pointer {
  cursor: pointer;
}
.overflow-auto {
  overflow: auto;
}