.unitchoose-pop {
  .ant-modal-content {

    // 搜索框
    .search-bar {
      display: flex;
      align-items: center;

      .component-areaCascader {
        width: 56%;

        .ant-cascader-picker {
          width: 100%;
          background-color: #FEFFFF;
        }
      }

      .component-searchInp {
        flex: 1;
        margin-left: 12px;

        .ant-input-affix-wrapper {
          height: 32px;
        }
      }
    }

    .content-box {

      // 列表
      .content-box-list {
        margin-top: 12px;
        max-height: 410px;
        overflow-y: scroll;

        .ant-radio-group {
          width: 100%;

          .ant-space {
            width: 100%;

            .list-item {
              margin: 0;
              width: 100%;

              .ant-radio {
                display: none;
              }

              .ant-radio+span {
                padding: 0;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                background: #ffffff;
                border: 1px solid #d9d9d9;

                .list-item-top {
                  align-items: center;
                  padding: 12px;

                  .list-item-top-left {
                    flex: 1;

                    .title {
                      margin-bottom: 12px;
                      font-size: 16px;
                      color: #333;
                      line-height: 22px;
                    }

                    .title-sub {
                      color: #999999;
                      line-height: 20px;
                    }
                  }

                  img {
                    width: 24px;
                    height: 24px;
                  }
                }

                .list-item-bottom {
                  padding: 0 12px;
                  height: 32px;
                  color: #666;
                  line-height: 32px;
                  background: #f1f1f1;
                }
              }
            }
          }
        }
      }

      // 滚动条整体粗细
      .content-box-list::-webkit-scrollbar {
        width: 8px;
      }

      // 滚动条内部
      .content-box-list::-webkit-scrollbar-thumb {
        border-radius: 4px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background:#C1C1C1;
      }

      /*滚动条轨道*/
      .content-box-list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #F1F1F1;
      }

      // 分页器
      .pagination-box {
        padding: 25px 0;

        .pop-pagination {
          justify-content: flex-end;
        }
      }
    }

    // 结果栏
    .result-box {
      justify-content: space-between;
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.90);
      line-height: 32px;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      box-sizing: content-box;

      .ant-btn {
        padding: 0;
        color: #ff3d5a;
        border: none;
        box-shadow: none;

        &::after {
          display: none;
        }
      }
    }
  }
}

.blue {
  color: #3662eb;
}

.red {
  color: #ff3d5a;
}