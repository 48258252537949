.reset-password-pop {

  .reset-password-form {
    .ant-form-item {
      margin-bottom: 24px;

      &:nth-last-child(1) {
        margin-bottom: 10px;
      }

      .ant-form-item-label {
        label {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.90);
        }
      }

      .ant-form-item-control {
        input {
          padding: 0 12px;
          font-weight: 400;
          line-height: 32px;
          color: #333333;
        }

        .ant-input-password {
          padding: 0;
          padding-right: 12px;

          input {
            border: none;
            box-shadow: none;
          }
        }
      }

      .email-vcode {
        position: relative;

        input {
          padding-right: 94px;
        }

        .email-vcode-btn {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 12px;
          margin: auto;
          padding: 0;
          color: #3662eb;
          line-height: 32px;
          border: none;
        }
      }
    }
  }
}