.register-page {
  margin: 62px auto 60px;
  padding: 40px 70px;
  width: 640px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  // 标题
  .ant-typography {
    margin-bottom: 35px;
    font-size: 22px;
    font-weight: 700;
    color: #333333;
  }

  // tab
  .ant-tabs-top {
    &>.ant-tabs-nav {
      margin-bottom: 24px;
      padding: 0 0 20px;
      border-bottom: 1px solid #cccccc;
    }

    &>.ant-tabs-nav::before {
      border: 0;
    }

    .ant-tabs-tab {
      padding: 0 0 12px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #2768b8;
      }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      height: 4px;
      background: inherit;

      &::before {
        margin: 0 auto;
        content: '';
        display: block;
        width: 20px;
        height: 4px;
        background: #3662eb;
        border-radius: 2px;
      }
    }

    // form
    .register-form {
      .ant-form-item {
        .ant-form-item-label {
          label {
            font-size: 16px;
            font-weight: 700;
            color: #333333;

            span+.anticon {
              margin-left: 8px;
            }
          }

          // 单位用户输入选择框label
          .flex-box {
            align-items: center;

            img {
              display: inline-block;
              margin-left: 8px;
              width: 18px;
              height: 18px;
            }
          }
        }

        .ant-form-item-control {
          input {
            padding: 0 24px 0 20px;
            height: 40px;
            line-height: 40px;
            background: #f4f4f4;
            border: none;

            &:focus {
              border: none;
              box-shadow: none;
            }
          }

          .ant-input-password {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            border: none;
            box-shadow: none;
            background: #f4f4f4;

            &:hover {
              background: #f4f4f4;
              border: none !important;
            }
          }

          .ant-input-password.ant-input-affix-wrapper-focused {
            border: none;
            box-shadow: none;
          }

          // 验证码
          .email-vcode {
            position: relative;

            input {
              padding-right: 164px;
            }

            .email-vcode-btn {
              position: absolute;
              right: 0;
              width: 140px;
              height: 40px;
              color: #ffffff;
              background: #3662eb;
              border: none;
            }
          }

          // 注册按钮
          .register-form-button {
            margin-top: 60px;
            padding: 0;
            width: 100%;
            height: 40px;
            font-size: 16px;
            color: #999999;
            background: #f4f4f4;
            border: none;

            &::after {
              display: none;
            }
          }

          .register-form-button.active {
            color: #ffffff;
            background: #3662eb;
          }

          // 单位用户输入选择框
          .ant-select.ant-select-single:not(.ant-select-customize-input) {
            .ant-select-selector {
              padding: 0 24px 0 20px;
              height: 40px;
              line-height: 40px;
              background: #f4f4f4;
              border: none;
              box-sizing: border-box;

              .ant-select-selection-search {
                left: 20px;
                right: 24px;

                input {
                  padding: 0;
                  background: unset;
                }
              }

              .ant-select-selection-placeholder {
                line-height: 40px;
              }
            }
          }
        }
      }

      // 反馈按钮
      .feedback {
        margin: 30px 0 10px;
        text-align: right;

        &+.ant-form-item {
          .register-form-button {
            margin-top: 0;
          }
        }

        .ant-form-item-control-input {
          min-height: auto;
        }

        .feedback-button {
          padding: 0;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #3662eb;
        }
      }

      // 四级下拉选框
      .address-box {
        .ant-form-item-control-input-content {
          &>.ant-row {
            &>.ant-col {
              display: flex;

              .ant-select-single:not(.ant-select-customize-input) {
                flex: 1;

                .ant-select-selector {
                  height: 40px;

                  .ant-select-selection-item {
                    line-height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 提示框
.ant-tooltip.custom-tooltip-box {
  max-width: 450px;

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      width: 12px;
      height: 12px;
      border: 2px solid #d9d9d9;
    }
  }

  .ant-tooltip-inner {
    padding: 8px 10px;
    font-size: 12px;
    color: #333333;
    border: 1px solid #d9d9d9;
  }
}