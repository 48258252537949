/* 前台页面结构样式 */
.server-layout {
  min-width: 1280px;
}
.server-layout > .server-layout-content {
  background-color: transparent;
  /* 头部 */
  /* 头部-首页 */
  /* 头部-首页固定 */
  /* 头部-在线观看 */
  /* 内容 */
}
.server-layout > .server-layout-content > .ant-layout-header {
  width: 100%;
  display: flex;
  background-color: #2768b8;
  padding: 0 40px;
  min-width: 1280px;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* 未登录 */
  /* 已登录 */
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .header-nav {
  display: flex;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .header-back > .ant-btn-text {
  color: #fff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .header-user {
  flex-shrink: 0;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .logo {
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  margin-right: 120px;
  flex-shrink: 0;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu.ant-menu-dark {
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu-item {
  line-height: 40px;
  padding: 0 24px;
  height: 40px;
  color: #ffffff;
  border-radius: 3px;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu-item:not(:last-child) {
  margin-right: 8px;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu-item:hover {
  background-color: #1757A7;
  color: #ffffff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .ant-menu-item-selected {
  background-color: #1757A7;
  color: #ffffff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .not-login {
  display: flex;
  align-items: center;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .not-login .item {
  height: 40px;
  padding: 0 24px;
  line-height: 40px;
  cursor: pointer;
  color: #fff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .not-login .item:hover {
  color: #ffffff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .not-login .ant-divider {
  height: 20px;
  margin: 0;
  border-radius: 1px;
  border-left: 1px solid #fff;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .is-login {
  display: flex;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .is-login .item {
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.server-layout > .server-layout-content > .ant-layout-header > .server-layout-header .is-login .item .anticon {
  font-size: 24px;
}
.server-layout > .server-layout-content > .ant-layout-header > .filter-bg {
  display: none;
}
.server-layout > .server-layout-content > .ant-layout-header-home {
  position: absolute;
  background-color: transparent;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.32);
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .logo {
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .ant-menu-item {
  color: #FFFFFF;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .ant-menu-item:hover {
  color: #FFFFFF;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .ant-menu-item-selected {
  color: #FFFFFF;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .not-login .item,
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .not-login .item:hover {
  color: rgba(255, 255, 255, 0.9);
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .not-login .ant-divider {
  border-left: 1px solid #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .is-login .item .anticon {
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .server-layout-header .is-login .item .ant-scroll-number {
  box-shadow: none;
}
.server-layout > .server-layout-content > .ant-layout-header-home > .fliter-bg {
  width: 100%;
  height: 64px;
  display: block;
  position: absolute;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(50px);
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .ant-menu-item {
  color: #333333;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .ant-menu-item:hover {
  color: #333333;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .ant-menu-item-selected {
  color: #333333;
  background-color: transparent;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .not-login .item,
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .not-login .item:hover {
  color: #fff;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .not-login .ant-divider {
  border-left: 1px solid #fff;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .is-login .item .anticon {
  color: #fff;
}
.server-layout > .server-layout-content > .ant-layout-header-home-fixed > .server-layout-header .is-login .item .ant-scroll-number {
  box-shadow: none;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch {
  background-color: #2768b8;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .logo {
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .ant-menu-item {
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .ant-menu-item:hover {
  background-color: #5F85FC;
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .ant-menu-item-selected {
  background-color: #5F85FC;
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .is-login .item .anticon {
  color: #FFFFFF;
}
.server-layout > .server-layout-content > .ant-layout-header-onliewatch > .server-layout-header .is-login .item .ant-scroll-number {
  box-shadow: none;
}
.server-layout > .server-layout-content > .ant-layout-content {
  min-width: 1280px;
  margin-top: 64px;
}
