.admin-left-menu {
  padding: 12px 8px;
  .ant-menu {
    &:not(.ant-menu-horizontal) {
      .ant-menu-item {
        height: 36px;
        line-height: 36px;
        margin: 4px 0;
        color: rgba(0,0,0,0.60);
      }
      .ant-menu-item-selected {
        background-color: #0052D9;
        color: #FFFFFF;
        &::after {
          display: none;
        }
      }
      /* 次级菜单 */
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          height: 36px;
          line-height: 36px;
          color: rgba(0,0,0,0.60);
        }
        .ant-menu-submenu-arrow {
          display: none;
        }
      }
      .ant-menu-submenu-open {
        .ant-menu-submenu-title {
          background-color: #0052D9;
          color: #FFFFFF;
        }
        .ant-menu-item-selected {
          background-color: #E7E7E7;
          color: rgba(0,0,0,0.60);
        }
      }
    }
  }
}