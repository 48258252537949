.server-layout-footer {
  // background-color: #2C395A;
  background-color: #2768b8;
  color: #FFFFFF;
  font-size: 14px;
  min-width: 1280px;
  padding: 0 40px;
  .server-container {
    >.item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      .flex-box {
        align-items: center;
      }
      .icon {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        position: relative;
        top: 2px;
        span {
          position: relative;
          top: 2px;
        }
      }
      .content {
        display: inline-block;
        font-size: 14px;
        line-height: 24px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
      }
      a {
        color: #FFFFFF;
        &:hover {
          opacity: .9;
        }
      }
      .ant-divider {
        height: 16px;
        border-width: 1px;
        border-color: #fff;
      }
    }
  }
}