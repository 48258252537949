.custom-popcomp {
  .ant-modal-content {
    .ant-modal-close {
      height: 70px;
    }

    .ant-modal-header {
      padding: 0 32px;
      display: flex;
      align-items: center;
      height: 70px;
      background: #ffffff;
      border-width: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.90);
      }
    }

    .ant-modal-body {
      padding: 0 32px;
    }

    .ant-modal-footer {
      padding: 20px 32px;
      border-width: 0;

      .ant-btn {
        padding: 0 16px;
        font-size: 14px;
        color: rgba(0,0,0,0.90);
        background: #eeeeee;
        border-radius: 3px;

        &:hover, &:focus {
          color: #2768b8;
          border-color: #2768b8;
        }
      }

      .ant-btn.ant-btn-primary {
        color: #ffffff;
        background: #2768b8;
        border-color: #2768b8;
      }

      .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 20px;
      }
    }
  }
}