.feedback-pop {
  .ant-modal-content {
    .feedback-form {
      .ant-form-item {
        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        .ant-form-item-label {
          label {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.90);
            line-height: 20px;
          }
        }

        .ant-form-item-control {
          input {
            padding-left: 12px;
            height: 32px;
            line-height: 32px;
            background: #feffff;
            border: 1px solid #dddddd;
            border-radius: 3px;
          }

          // 单位选择输入框
          .ant-input-affix-wrapper {
            padding: 0 11px 0 0;

            input {
              border: none;
            }
          }

          // 下拉框
          .ant-select.ant-select-single:not(.ant-select-customize-input) {
            .ant-select-selector {
              padding-left: 11px;
              height: 32px;
              line-height: 32px;
              background: #feffff;
              border: 1px solid #dddddd;

              .ant-select-selection-item {
                line-height: 32px;
              }
            }

            .ant-select-selection-placeholder {
              height: 32px;
              line-height: 32px;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 32px;

      .ant-btn.ant-btn-primary {
        color: #999999;
        background: #f4f4f4;
        border-color: #f4f4f4;
      }

      .ant-btn.ant-btn-primary.active {
        color: #ffffff;
        background: #2768b8;
        border-color: #2768b8;
      }
    }
  }
}