.admin-layout-footer {
  display: flex;
  align-items: center;
  color: #999999;
  justify-content: center;
  height: 60px;
  a {
    color: #999999;
    margin-left: 15px;
  }
}